import React, { useState, useEffect, Fragment } from 'react'
import { Box, InputAdornment, TextField, IconButton } from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { MergeType } from '@material-ui/icons'

const dartmouthGreenColor = '#2D7638'
const themeGreenColor = '#39CFBA'

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiInputLabel-outlined ': {
			//color: '#39CFBA',
			fontFamily: 'Roboto Light,sans-serif'
		},
		'& .MuiInputLabel-formControl': {
			// color: '#39CFBA',
		},
		'& label.Mui-focused': {
			//color: themeGreenColor, *floating label color
		},
		'& .MuiInput-underline:after': {
			// borderBottomColor: dartmouthGreenColor,
		},
		'& $checked': {
			//color: '#62C370',
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: '0px',
			'& .MuiOutlinedInput-input': {
				fontFamily: 'Roboto Medium,sans-serif'
				//fontSize: 'clamp(13px,2vw,20px)'
			},

			'& fieldset': {
				//borderColor: themeGreenColor,
			},
			'&:hover fieldset': {
				//borderColor: themeGreenColor,
			},
			'&.Mui-focused fieldset': {
				//borderColor: mingColor, * when focus feild
			}
		}
	},

	feildTextHint: {
		fontFamily: 'Roboto light,sans-serif',
		float: 'right',
		fontSize: '14px',
		position: 'absolute',
		right: 0,
		top: '-17px',
		color: '#2699FB'
	},
	textWrapper: {
		position: 'relative'
	},
	hiddenText: {
		color: 'transparent'
	}
}))
// endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
export default function InputText(props) {
	const {
		name,
		label,
		value,
		type,
		themeColor = 'primary',
		charLeft,
		error = null,
		limit = null,
		onChange,
		onFocus,
		endAdornmentText = null,
		disabled = false,
		minlength,
		maxlength
	} = props
	const classes = useStyles()

	const onEnter = (e) => {
		if (e.key === "Enter") {
			e.target.blur();
		}
	};

	return (
		<Box className={classes.textWrapper}>
			{charLeft > -1 && <Box className={classes.feildTextHint}>{`${charLeft} characters left`}</Box>}
			<TextField
				inputProps={{
					maxLength: limit,
					inputMode: type,
					minLength: minlength,
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							{endAdornmentText !== null ? endAdornmentText : ''}
						</InputAdornment>
					)
				}}
				className={classes.root}
				variant='outlined'
				color={themeColor}
				size='medium'
				label={label}
				disabled={disabled}
				type={type}
				name={name}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				onKeyUp={onEnter}
				helperText={<span className={classes.hiddenText}>*</span>}
				{...(error && { error: true, helperText: error })}
				
			/>
		</Box>
	)
}

// medium | small
