import React, { Fragment } from 'react'
import {
	Box,
	Container,
	FormGroup,
	FormControl,
	FormControlLabel,
	Checkbox as MuiCheckBox
} from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	checkBoxLabel: {
		marginBottom: '-20px',
		'& .MuiFormControlLabel-label ': {
			color: '#0e2c39',
			fontSize: '16px',
			fontFamily: 'Roboto Light,sans-serif',
			[theme.breakpoints.down('xs')]: {
				fontSize: '13px',
				marginTop: '15px'
			}
		}
	}
}))

export default function CheckBox(props) {
	const { name, label, value, onChange } = props
	const classes = useStyles()

	return (
		<Fragment>
			<FormGroup row className={classes.checkBoxLabel}>
				<FormControl>
					<FormControlLabel
						control={
							<MuiCheckBox color='primary' name={name} checked={value} onChange={onChange} />
						}
						label={label}
					/>
				</FormControl>
			</FormGroup>
		</Fragment>
	)
}
