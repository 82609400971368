import { takeLatest } from 'redux-saga/effects'
import * as types from '../redux/types'
import * as typesResetPassword from '../redux/typesResetPassword'

import { getCurrentUserInfo, dispensaryOwnerSignIn, signOut } from './Sagas'

import { resetPasswordSaga, submitForgotPasswordSaga } from './resetPasswordSaga'

export const authSagas = [
	takeLatest(types.DISPENSARY_OWNER_SIGN_IN_REQUEST, dispensaryOwnerSignIn),
	takeLatest(types.GET_CURRENT_USER_INFO, getCurrentUserInfo),
	takeLatest(types.SIGNOUT, signOut),

	takeLatest(typesResetPassword.RESET_PASSWORD_AWS_REQUEST, resetPasswordSaga),
	takeLatest(typesResetPassword.SUBMIT_PASSWORD_RESET, submitForgotPasswordSaga)
]
