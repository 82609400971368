import createSagaMiddleware from 'redux-saga'
import { createStore, compose, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createHashHistory as createHistory } from 'history'
import logger, { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createBlacklistFilter, createWhitelistFilter } from 'redux-persist-transform-filter'

import rootReducer from './rootReducer'
import sagas from './rootSaga'
export const history = createHistory({ forceRefresh: true })


/**
 * redux persist configs
 **/
const persistConfig = {
	key: 'root',
	storage: storage,
	whitelist: ['authReducer'],
	transforms: [createBlacklistFilter(), createWhitelistFilter('dispensaryReducer', ['step'])]
}

const logMiddleware = createLogger({
	predicate: () => process.env.NODE_ENV.trim() !== 'production'
})

const sagaMiddleware = createSagaMiddleware()

export function configureStore(initialState) {
	const reactRouterMiddleware = routerMiddleware(history)
	const middlewares = [sagaMiddleware, reactRouterMiddleware]

	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
	/**
	 * TODO
	 * Activate :: persistedReducer & line#[25]/[33] and comment =>  rootReducer(history),
	 */
	const persistedReducer = persistReducer(persistConfig, rootReducer(history))
	const store = createStore(
		// rootReducer(history),
		persistedReducer,
		initialState,
		composeEnhancers(applyMiddleware(...middlewares, logMiddleware))
	)

	sagaMiddleware.run(sagas)

	const persistor = persistStore(store)

	return { store, persistor }
}
