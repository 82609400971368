import React, { Fragment } from 'react'
import {
	Box,
	Container,
	FormGroup,
	FormControl,
	FormControlLabel,
	Checkbox as MuiCheckBox
} from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import * as appActions from '../../../../store/AppStore/appActions'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	termsElementsBox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		// alignItems: 'flex-end',
		margin: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(2)}px ${theme.spacing(0)}px`,
		[theme.breakpoints.down('xs')]: {
			margin: `${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(5)}px ${theme.spacing(0)}px`
		}
	},
	checkboxTermsLabelText: {
		padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
		fontSize: '16px',
		color: '#0E2C39',
		fontFamily: 'Roboto Light ,sans-serif',
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px'
		}
	},
	checkboxDefaultLabelText: {
		padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px'
		}
	},
	boldText: {
		color: '#2699FB'
	}
}))

export default function CheckBoxNavtive(props) {
	const { name, label = null, value, onChange } = props
	const classes = useStyles()

	const dispatch = useDispatch()

	const showFaq = () => {
		const payload = {
			show: true,
			content: 'FAQ'
		}
		dispatch(appActions.showModal(payload))
	}

	const showTnC = () => {
		const payload = {
			show: true,
			content: 'TnC'
		}
		dispatch(appActions.showModal(payload))
	}

	return (
		<Box component='div' className={classes.termsElementsBox}>
			<label className='checkbox-block'>
				<input type='checkbox' defaultChecked={value} onChange={onChange} name={name} />
				<span className='checkmark'></span>
			</label>

			{label ? (
				<span className={classes.checkboxDefaultLabelText}>{label}</span>
			) : (
				<span className={`${classes.checkboxTermsLabelText} checkbox-text`}>
					By creating an account you agree to our{' '}
					<strong className={classes.boldText} onClick={showTnC}>
						Terms of Service
					</strong>{' '}
					{/* and{' '}
					<strong className={classes.boldText} onClick={showFaq}>
						Privacy Policy
					</strong> */}
				</span>
			)}
		</Box>
	)
}
