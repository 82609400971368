import React, { useState, useEffect } from 'react'
import { Box, Container } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { ArrowBack, Close } from '@material-ui/icons'

import TrainStopers from 'containers/forms/components/TrainStopers'

const useStyles = makeStyles(theme => ({
	toolBox: {
		// borderBottom: '1px dotted red',
		flexGrow: 1,
		display: 'grid',
		gridTemplateColumns: '1fr 3fr 1fr',
		//padding: '20px 2px',
		padding: `${theme.spacing(2.5)}px ${theme.spacing(0.25)}px`,

		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: '1fr auto',
			borderBottom: '1px solid #eee',
			// marginBottom: '20px'
			marginBottom: `${theme.spacing(2.5)}px`
		}
	},
	toolBoxBackArrow: {
		cursor: 'pointer',
		color: '#39CFBA',
		paddingTop: '2px',
		'&:hover': {
			color: '#159782'
		}
	},

	primaryHeading: {
		marginTop: '-22px',
		flexGrow: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		fontSize: 'clamp(24px,5vw, 50px)',
		fontFamily: 'Roboto Condensed ,sans-serif',
		color: '#0e2c39',
		[theme.breakpoints.down('xs')]: {
			display: 'none'
		}
	},
	toolBoxLeft: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			flexGrow: 1
		}
	},
	toolBoxTrainstop: {
		marginLeft: theme.spacing(1)
	},
	toolBoxRight: {
		marginRight: theme.spacing(0),
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		cursor: 'pointer',
		color: '#39CFBA',
		//marginTop: '-1rem',

		'&:hover': {
			color: '#159782'
		}
	},

	toolBoxRightBTN: {
		color: '#159782',
		backgroundColor: 'transparent'
	}
}))

const FormToolbar = props => {
	const classes = useStyles()
	const theme = useTheme()

	const { mainHeading, prevArrow, stepper } = props

	const onPressPressedClose = () => {
		const { exitForm } = props
		exitForm()
	}

	const onPressPressedBack = () => {
		const { prevStep } = props
		prevStep()
	}

	return (
		<Box component='div' className={classes.toolBox}>
			<div className={classes.toolBoxLeft}>
				<Box component='div' className={classes.toolBoxBackArrow}>
					{prevArrow ? (
						<span onClick={onPressPressedBack}>
							<ArrowBack />
						</span>
					) : null}
				</Box>
				<Box component='div' className={classes.toolBoxTrainstop}>
					{stepper !== null && <TrainStopers stepper={stepper} />}
				</Box>
			</div>
			<div className={classes.primaryHeading}>{mainHeading}</div>
			<div className={classes.toolBoxRight}>
				<span onClick={onPressPressedClose}>
					<Close />
				</span>
			</div>
		</Box>
	)
}

export default FormToolbar
