 
/**
 * FYI: This is a demo for class components
 * To use simple-keyboard with React Hooks / Function components, check out:
 * https://simple-keyboard.com/react/demo/hooks
 */
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
import inputMask from "simple-keyboard-input-mask";
import { makeStyles } from '@material-ui/core/styles'
import "react-simple-keyboard/build/css/index.css";
import "./index.css"
import Grid from '@material-ui/core/Grid'
import {
  Box,
  Container,
  Toolbar,
  AppBar, Typography,
  Button
} from '@material-ui/core'

class DefaultNumberKeyboard extends Component {

  constructor(props) {
    super(props)
    const {onClickEnter} = props
  }

  state = {
      layoutName: "default",
      input: ""
    };


    commonKeyboardOptions = {
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
    };


    keyboardOptions = {
      ...this.commonKeyboardOptions,

      display: {
        "{backspace}": "⌫ Delete",
        "{enter}": "Next  >>",
      }
    };
  
    onChange = input => {
      this.setState({ input });
    };
  
    onKeyPress = button => {
      if (button === "{enter}") {
        this.props.onClickEnter(this.state.input)
      }
    };
  
    onChangeInput = event => {
      const input = event.target.value;
      this.setState({ input });
      this.keyboard.setInput(input);
    };

    componentDidMount(){
      this.nameInput.focus();
    }
  
    render() {
      const { classes } = this.props;
      return (
        <div>

          <Grid item xs={12} class="labelRow">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                  <Grid container style={{justifyContent: 'flex-end'}}>
                    <Grid item  class="labelWrapper">
                      <Typography class="formLabel">Enter Mobile Number</Typography>
                      <Typography class="formLabelSinhala">ජංගම දුරකථන අංකය</Typography>
                    </Grid>
                  </Grid>
                  
              </Grid>
              <Grid item xs={12} sm={8}>
                  {
                  <div class="input-wrapper">
                    <input
                        value={0}
                        onChange={this.onChangeInput}
                        class="number-input"
                        readOnly
                    />
                    <input
                        value={7}
                        onChange={this.onChangeInput}
                        class="number-input"
                        readOnly
                    />
                    <input inputmode='none'
                        value={this.state.input}
                        onChange={this.onChangeInput}
                        class="number-input-editable"
                        maxLength="8"
                        ref={(input) => { this.nameInput = input; }}
                    />      
              
                </div>

                  }
                <div><Grid item xs={12}>
                  <Keyboard
                    keyboardRef={r => (this.keyboard = r)}
                    layoutName={this.state.layoutName}
                    theme="hg-theme-default simple-keyboard my-theme hg-button default-button"
                    layout={{
                      default: [
                        "= 1 2 3 =",
                        "= 4 5 6 =",
                        "= 7 8 9 =",
                        "{backspace} 0 {enter} +",
                      ]}}
                    inputMask={{
                        "default": {
                          mask: '77777777',
                          regex: /^[0-9]/,                    
                      }
                    }}
                    modules={[inputMask]}

                    buttonTheme={[
                      {
                        class: "default-number-button",
                        buttons: "0 1 2 3 4 5 6 7 8 9"
                      },
                      {
                        class: "delete-number-button",
                        buttons: "{backspace}"
                      },
                      {
                        class: "next-number-button",
                        buttons: "{enter}"
                      },
                      {
                        class: "hide-button4",
                        buttons: "="
                      },
                      {
                        class: "hide-button5",
                        buttons: "+"
                      },

                  ]
                  }
                  {...this.keyboardOptions}

            />
          </Grid></div>

              </Grid>
            </Grid>
          </Grid>
          
        </div>

    );
  }
}

//render(<App />, document.getElementById("root"));



export default DefaultNumberKeyboard
