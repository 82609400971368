import * as types from './types'

const defaultState = {
	step: 1,
	formInputs: {
		fullname: '',
		email: '',
		message: ''
	},
	contactUsResponse: {}
}

const contactReducer = (state = defaultState, action) => {
	switch (action.type) {
		case types.SET_CONTACT_FORM_FIELD_VALUE:
			return {
				...state,
				formInputs: {
					...state.formInputs,
					[action.payload.fieldname]: action.payload.value
				}
			}
		case types.UPDATE_CONTACT_CURRENT_FORM_STEP:
			return {
				...state,
				step: action.payload
			}
		case types.RESET_CONTACT_FORM_VALUES:
			return {
				...state,
				formInputs: defaultState.formInputs
			}
		case types.CONTACT_US_RESPONSE:
			return {
				...state,
				contactUsResponse: action.payload
			}
		default:
			return state
	}
}

export default contactReducer
