import Axios from 'axios'
import { ApiConstants } from 'constants/ApiConstants'

export function registerDispensary(data, options) {
	return Axios.post(ApiConstants.BASE_URL + 'dispensary', data, options)
}

export function assignStaffToDispensary(data, options) {
	return Axios.post(ApiConstants.BASE_URL + 'dispensary/add_staff', data, options)
}
