import * as types from './staffTypes'

const defaultState = {
	step: 2,
	title: 'Add a Dispensary Staff',
	formInputs: {
		isAdmin: false,
		firstName: '',
		lastName: '',
		username: '',
		password: ''
	},
	awsCreateStaff: {},
	responseCreateStaff: {
		uuid: '',
		status: ''
	},
	dispensaryStaff: [],
	staffOtp: false,
	staffForgotPasswordResponse: { uuid: '', status: '', message: '' },
	assignStaffToDispensaryResponse: {
		uuid: '',
		show: '',
		message: '',
		status: '201'
	},
	resetStaffPasswordResponse: {
		uuid: '',
		status: ''
	},
	confirmStaffUserResponse: {
		uuid: '',
		status: ''
	}
}

const staffReducer = (state = defaultState, action) => {
	switch (action.type) {
		case types.SET_STAFF_FORM_FIELD_VALUE:
			return {
				...state,
				formInputs: {
					...state.formInputs,
					[action.payload.fieldname]: action.payload.value
				}
			}

		case types.UPDATE_USER_ROLE_STATUS:
			return {
				...state,
				formInputs: {
					...state.formInputs,
					[action.payload.fieldname]: action.payload.status
				}
			}
		case types.UPDATE_CURRENT_FORM_STEP:
			return {
				...state,
				step: action.payload
			}
		case types.SUBMIT_DISPENSARY_VALUES:
			return {
				...state,
				visibility: action.payload
			}

		case types.RESET_DISPENSARY_STAFF_FORM_VALUES:
			return {
				...state,
				formInputs: defaultState.formInputs
			}
		case types.CREATE_STAFF_MEMBER_RESPONSE:
			return {
				...state,
				responseCreateStaff: action.payload
			}
		case types.SUBMIT_DISPENSARY_STAFF_API_RESPONSE:
			return {
				...state,
				assignStaffToDispensaryResponse: action.payload
			}
		case types.SET_STAFF_DETAILS_TO_FORM:
			return {
				...state,
				formInputs: action.payload
			}
		case types.STAFF_FORGOT_PASSWORD_RESPONSE:
			return {
				...state,
				staffForgotPasswordResponse: action.payload
			}
		case types.SUBMIT_STAFF_PASSWORD_RESPONSE:
			return {
				...state,
				resetStaffPasswordResponse: action.payload
			}
		case types.CONFIRM_STAFF_USER_RESPONSE:
			return {
				...state,
				confirmStaffUserResponse: action.payload
			}
		default:
			return state
	}
}

export default staffReducer
