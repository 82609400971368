export const SET_FORM_FIELD_VALUE = 'SET_FORM_FIELD_VALUE'
export const SET_FORM_FIELD_HINT = 'SET_FORM_FIELD_HINT'
export const UPDATE_CURRENT_FORM_STEP = 'UPDATE_CURRENT_FORM_STEP'
export const UPDATE_CONDITION_AND_TERMS = 'UPDATE_CONDITION_AND_TERMS'
export const RESET_DISPENSARY_FORM_VALUES = 'RESET_DISPENSARY_FORM_VALUES'
export const SET_LOCATION_AND_ADDRESS = 'SET_LOCATION_AND_ADDRESS'

export const AWS_CREATE_DISPENSARY_OWNER_ACCOUNT_REQUEST = 'AWS_CREATE_DISPENSARY_OWNER_ACCOUNT_REQUEST'
export const AWS_CREATE_DISPENSARY_OWNER_ACCOUNT_RESPONSE = 'AWS_CREATE_DISPENSARY_OWNER_ACCOUNT_RESPONSE'
export const AWS_GET_LOGGED_IN_DISPENSARY_OWNER_INFO = 'AWS_GET_LOGGED_IN_DISPENSARY_OWNER_INFO'
export const UPDATE_DISPENSARY_OWNER_INFO = 'UPDATE_DISPENSARY_OWNER_INFO'
export const AWS_CONFIRM_OTP_REQUEST = 'AWS_CONFIRM_OTP_REQUEST'
export const AWS_CONFIRM_OTP_RESPONSE = 'AWS_CONFIRM_OTP_RESPONSE'

export const CREATE_DISPENSARY_REQUEST = 'CREATE_DISPENSARY_REQUEST'
export const CREATE_DISPENSARY_RESPONSE = 'CREATE_DISPENSARY_RESPONSE'
export const FLOWLESS_REGISTRATION = 'FLOWLESS_REGISTRATION'

export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST'
export const RESEND_OTP_RESPONSE = 'RESEND_OTP_RESPONSE'

export const SET_DISPENSARY_DETAILS_TO_FORM = 'SET_DISPENSARY_DETAILS_TO_FORM'

export const UPDATE_DISPENSARY_INFO_REQUEST = 'UPDATE_DISPENSARY_INFO_REQUEST'
export const UPDATE_DISPENSARY_INFO_RESPONSE = 'UPDATE_DISPENSARY_INFO_RESPONSE'

export const SET_TEMP_DISPENSARY = 'SET_TEMP_DISPENSARY'
export const CLEAR_TEMP_DISPENSARY = 'CLEAR_TEMP_DISPENSARY'
