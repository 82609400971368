import React, { useState, useEffect, Fragment } from 'react'
import {
	Box,
	Container,
	FormGroup,
	InputLabel,
	FormControl,
	OutlinedInput,
	Select as MuiSelect,
	MenuItem,
	FormHelperText,
	InputBase
} from '@material-ui/core'

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'

const dartmouthGreenColor = '#2D7638'
const themeGreenColor = '#39CFBA'

const BootstrapInput = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3)
		}
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
		}
	}
}))(InputBase)

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiInputLabel-root ': {
			//color: '#39CFBA',
			fontFamily: 'Roboto light,sans-serif !important'
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: '0px',
			'& fieldset': {
				//borderColor: themeGreenColor,
			}
		},
		'& .MuiSelect-icon': {
			//color: '#39CFBA',
			top: 'calc(50% - 12px)',
			width: '24px',
			height: '24px'
		},
		'& .MuiPopover-paper': {
			marginTop: '55px'
		}
	},
	hiddenText: {
		color: 'transparent'
	}
}))

export default function Select(props) {
	const { name, label, value, option, error = null, lblWidth = 200, onChange } = props
	const classes = useStyles()

	return (
		<Fragment>
			<FormControl className={classes.root} variant='outlined' {...(error && { error: true })}>
				<InputLabel>{label}</InputLabel>
				<MuiSelect
					native
					value={value}
					onChange={onChange}
					input={
						<OutlinedInput name={name} labelWidth={lblWidth} id='outlined-age-native-simple' />
					}>
					<option value='' />
					{option.map((item, idx) => (
						<option key={idx} value={item.value}>
							{item.title}
						</option>
					))}
				</MuiSelect>
				{error ? (
					<FormHelperText>{error}</FormHelperText>
				) : (
					<span className={classes.hiddenText}>*</span>
				)}
			</FormControl>
		</Fragment>
	)
}
