export const NAVBAR_ROUTES = {
	HOME: '/',
	CONTACT_US: '/contact',
	OWNER_LOGIN: '/dispensary/login',
	OWNER_LOGIN_RESET: '/dispensary/password-reset',
	OWNER_PORTAL_WEB: '/dispensary/portal-web',
	DESPENSARY_SIGNUP_FORM: '/dispensary/signup',
	DESPENSARY_STAFF_SIGNUP_FORM: '/dispensary/staff/signup',
	DOCTOR_SIGNUP_FORM: '/doctor/signup',
	SCUCCESS_DESPENSARY_SUBMITION: '',
	SCUCCESS_DOCTOR_SUBMITION: '',
	LOGOUT: '/logout',
	MOBILE_DOWNLOAD: '/mobile-download',
	KIOSK: '/kiosk',
	KIOSK_MOBILE_NUMBER: '/kiosk-mobile-number',
	NOW_SERVING_LAYOUT:'/now-serving',
	KIOSK_APPOINTMENT_LAYOUT: '/kiosk-appointment'
}
