import { NAVBAR_ROUTES } from 'constants/NavConstants'

import HomePage from 'components/HomePage'
import DispensaryLayout from './containers/forms/Dispensary/DispensaryLayout'
import DoctorLayout from './containers/forms/doctor/DoctorLayout'
import DispensaryStaffLayout from './containers/forms/Dispensary/DispensaryStaffLayout'
import LoginPage from './containers/forms/auths/LoginPage'
import ContactUsPage from './containers/forms/contactus/ContactUsPage'
import PortalWebPage from './containers/portalweb/PortalWebPage'
import PasswordUpdatedLayout from './containers/forms/auths/PasswordUpdatedLayout'
import MobileDownload from 'components/MobileDownload'
import KioskDetailsForm from 'containers/forms/Kiosk/KioskDetailsForm'
import KioskAppointmentLayout from 'containers/forms/Kiosk/KioskAppointmentLayout'
import KioskNumberForm from 'containers/forms/Kiosk/KioskNumberForm'
import NowServingLayout from 'containers/forms/NowServingNumber/NowServingLayout'



const routes = [
	{ path: NAVBAR_ROUTES.HOME, component: HomePage, exact: true },
	{ path: NAVBAR_ROUTES.MOBILE_DOWNLOAD, component: MobileDownload, exact: true },
	{ path: NAVBAR_ROUTES.DESPENSARY_SIGNUP_FORM, component: DispensaryLayout, exact: true },
	{ path: NAVBAR_ROUTES.DESPENSARY_STAFF_SIGNUP_FORM, component: DispensaryStaffLayout, exact: true },
	{ path: NAVBAR_ROUTES.DOCTOR_SIGNUP_FORM, component: DoctorLayout, exact: true },
	{ path: NAVBAR_ROUTES.CONTACT_US, component: ContactUsPage, exact: true },
	{ path: NAVBAR_ROUTES.OWNER_LOGIN, component: LoginPage, exact: true },
	{ path: NAVBAR_ROUTES.OWNER_LOGIN_RESET, component: PasswordUpdatedLayout, exact: true },
	{ path: NAVBAR_ROUTES.OWNER_PORTAL_WEB, component: PortalWebPage, exact: true },
	{ path: NAVBAR_ROUTES.KIOSK, component: KioskDetailsForm, exact: true },
	{ path: NAVBAR_ROUTES.KIOSK_APPOINTMENT_LAYOUT, component: KioskAppointmentLayout, exact: true },
	{ path: NAVBAR_ROUTES.KIOSK_MOBILE_NUMBER, component: KioskNumberForm, exact: true },
	{ path: NAVBAR_ROUTES.NOW_SERVING_LAYOUT, component: NowServingLayout, exact: true }

]

export default routes
