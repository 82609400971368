import * as types from './types'

export function getDispensaryDetailsRequest() {
	return {
		type: types.GET_DISPENSARY_DETAILS_REQUEST
	}
}

export function getDispensaryDetailsResponse(payload) {
	return {
		type: types.GET_DISPENSARY_DETAILS_RESPONSE,
		payload
	}
}

export function setDispensaryDetails(payload) {
	return {
		type: types.SET_DISPENSARY_DETAILS,
		payload
	}
}

export function getDoctorListRequest() {
	return {
		type: types.GET_DOCTOR_LIST_REQUEST
	}
}

export function setDoctorList(payload) {
	return {
		type: types.SET_DOCTOR_LIST,
		payload
	}
}

export function getStaffListRequest() {
	return {
		type: types.GET_STAFF_REQUEST
	}
}

export function setStaffList(payload) {
	return {
		type: types.SET_STAFF,
		payload
	}
}
