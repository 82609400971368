export const phone_number = state => {
	if (state.authReducer.currentUserInfo.attributes.email) {
		return state.authReducer.currentUserInfo.attributes.email
	}
	return ''
}

export const reset_password_email = state => {
	if (state.resetPasswordReducer.formInputs.email) {
		return state.resetPasswordReducer.formInputs.email
	}
	return ''
}

export const password = state => {
	if (state.resetPasswordReducer.formInputs.password) {
		return state.resetPasswordReducer.formInputs.password
	}
	return ''
}

export const otp = state => {
	if (state.resetPasswordReducer.otp) {
		return state.resetPasswordReducer.otp
	}
	return ''
}

export const dispensary_id = (state) =>
    state.authReducer.dispensaryInfo ? state.authReducer.dispensaryInfo.id : '';

export const modify_slot = (state) => 
	state.appointmentsReducer.modifySlot;
