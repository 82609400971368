import React from 'react'
import { HashRouter as Router, Switch } from 'react-router-dom'
import { Box, CssBaseline } from '@material-ui/core'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

import { connect } from 'react-redux'
import * as authActions from '../containers/forms/auths/redux/actions'

import AppFooter from '../components/Footer'
import Navbar from '../components/Navbar'
import renderRoutesInPath from 'utils/RouterUtils'
import routes from 'routes'
import '../styles/main.scss'
import { bindActionCreators } from 'redux'
import AppsyncSubscription from '../components/appsyncSubscription/AppsyncSubscription'

// const theme = createMuiTheme({
//   palette: {
//     primary: {
//       main: "#39cfba",
//       light: "#39CFBA",
//       dark: "#159782",
//       contrastText: "#FFF",
//     },
//     secondary: {
//       main: "#FFFFFF",
//       light: "",
//       dark: "",
//       contrastText: "#F4F4F4",
//     },
//     error: {
//       main: "#ba0000",
//       light: ""
//     },
//     background: "#f4f5fd"
//   },
// });

class App extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isLoggedIn: false
		}
	}

	componentDidMount() {
		this.props.authActions.getCurrentUserInfo()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.auth.loggedIn !== this.props.auth.loggedIn) {
			this.setState({ isLoggedIn: this.props.auth.loggedIn })
		}
	}



	render() {
		
		const { loggedIn } = this.state
		const { auth } = this.props
		return (
			// <ThemeProvider theme={theme}>
			
			<div className='App'>
				<AppsyncSubscription />
				<Router>
					<Navbar />
					<Box component='div' id='page_wrapper'>
						<Switch children={renderRoutesInPath(routes)} />
					</Box>
					<AppFooter />
				</Router>
				<CssBaseline />
			</div>
			// </ThemeProvider>
		)
	}
}

function mapStateToProps(state) {
	return {
		auth: state.authReducer
	}
}

function mapDispatchToProps(dispatch) {
	return {
		authActions: bindActionCreators(authActions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
