import * as staffTypes from './staffTypes'

export function onChangeField(fieldname, value) {
	return {
		type: staffTypes.SET_STAFF_FORM_FIELD_VALUE,
		payload: { fieldname, value }
	}
}

export function updateFormStep(step) {
	return {
		type: staffTypes.UPDATE_CURRENT_FORM_STEP,
		payload: step
	}
}

export function updateUserRoleCondition(fieldname, status) {
	return {
		type: staffTypes.UPDATE_USER_ROLE_STATUS,
		payload: { fieldname, status }
	}
}

export function resetForm() {
	return {
		type: staffTypes.RESET_DISPENSARY_STAFF_FORM_VALUES
	}
}

export function createStaffMemberAws(payload) {
	return {
		type: staffTypes.CREATE_STAFF_MEMBER_REQUEST,
		payload
	}
}

export function createStaffMemberAwsResponse(payload) {
	return {
		type: staffTypes.CREATE_STAFF_MEMBER_RESPONSE,
		payload
	}
}

export function submitDispensaryStaffForm(payload) {
	return {
		type: staffTypes.SUBMIT_DISPENSARY_STAFF_API_REQUEST,
		payload
	}
}

export function submitDispensaryStaffFormResponse(payload) {
	return {
		type: staffTypes.SUBMIT_DISPENSARY_STAFF_API_RESPONSE,
		payload
	}
}

export function setCurrentStaffMemberDetails(payload) {
	return {
		type: staffTypes.SET_STAFF_DETAILS_TO_FORM,
		payload
	}
}

export function resetStaffPassword(payload) {
	return {
		type: staffTypes.UPDATE_STAFF_PASSWORD,
		payload
	}
}

export function updateDispensaryStaff(payload) {
	return {
		type: staffTypes.UPDATE_DISPENSARY_STAFF,
		payload
	}
}

export function staffForgotPasswordResponse(payload) {
	return {
		type: staffTypes.STAFF_FORGOT_PASSWORD_RESPONSE,
		payload
	}
}

export function submitStaffPasswordResetRequest(payload) {
	return {
		type: staffTypes.SUBMIT_STAFF_PASSWORD_REQUEST,
		payload
	}
}

export function submitStaffPasswordResetResponse(payload) {
	return {
		type: staffTypes.SUBMIT_STAFF_PASSWORD_RESPONSE,
		payload
	}
}

export function confirmStaffUserAwsRequest(payload) {
	return {
		type: staffTypes.CONFIRM_STAFF_USER_REQUEST,
		payload
	}
}

export function confirmStaffUserAwsResponse(payload) {
	return {
		type: staffTypes.CONFIRM_STAFF_USER_RESPONSE,
		payload
	}
}
