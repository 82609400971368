import * as types from './types'

export function showLoading() {
	return {
		type: types.SHOW_LOADER
	}
}

export function showSendSmsLoading() {
	return {
		type: types.SHOW_SEND_SMS_LOADER
	}
}

export function hideSendSmsLoading() {
	return {
		type: types.HIDE_SEND_SMS_LOADER
	}
}

export function hideFullLoader() {
    return {
        type: types.HIDE_FULL_LOADER
    };
}

export const hideLoading = () => {
	return {
		type: types.HIDE_LOADER
	}
}

export function showError(payload) {
	return {
		type: types.SHOW_ERROR_LOADER,
		payload
	}
}

export const hideError = () => {
	return {
		type: types.HIDE_ERROR_LOADER
	}
}

export const showModal = payload => {
	return {
		type: types.SHOW_MODAL,
		payload
	}
}

export const hideModal = payload => {
	return {
		type: types.HIDE_MODAL,
		payload
	}
}
