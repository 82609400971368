import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Container, Grid } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { ArrowBack, Close } from '@material-ui/icons'
import Controls from 'containers/forms/components/controls'
import Widgets from 'components/widgets'
import { NAVBAR_ROUTES } from 'constants/NavConstants'

const useStyles = makeStyles(theme => ({
	root: {
		flex: 1,
		width: '100%',
		height: '100%',
		marginTop: theme.spacing(20),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(10)
		}
	},
	messageMainText: {
		color: '#0E2C39',
		fontSize: 'clamp(22px,calc(1vw + 1.2rem), 50px)', // 20px
		fontFamily: 'Roboto Bold ,sans-serif',
		padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
		[theme.breakpoints.down('xs')]: {
			fontSize: '22px'
		}
	},

	messageSubText: {
		color: '#0E2C39',
		fontSize: 'clamp(20px, calc(1vw + 0.8rem), 40px)', // 20px
		fontFamily: 'Roboto Light ,sans-serif',
		padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`
	},
	messageBtn: {
		padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`
	}
}))

const ContactSuccessMessage = props => {
	const classes = useStyles()
	const theme = useTheme()
	const { onPressed } = props
	return (
		<Box
			component='div'
			display='flex'
			flexDirection='column'
			justifyContent='flext-start'
			alignItems='center'
			className={classes.root}>
			<div className={classes.messageMainText}>Thanks for submitting the form!</div>
			<div className={classes.messageSubText}>
				We’ll get back to you
				<br /> as soon as possible
			</div>
			<div className={classes.messageBtn}>
				<Box component='div' display='flex' flexDirection='row' justifyContent='center'>
					<Widgets.ButtonBox
						onClick={onPressed}
						type='submit'
						disabled={false}
						label='CLOSE'
						variantType='outlined'
						themeColor='secondary'
						size='large'
					/>
				</Box>
			</div>
		</Box>
	)
}

export default ContactSuccessMessage
