import Axios from 'axios'
import { ApiConstants } from 'constants/ApiConstants'

export function getDispensaryData(email, options) {
	return Axios.get(ApiConstants.BASE_URL + 'dispensary/' + email, options)
}

export function getDoctorDetails(payload, options) {
	const params = new URLSearchParams(payload).toString()
	return Axios.get(ApiConstants.BASE_URL + 'doctor/profile?' + params, options)
		.then(response => response.data)
		.catch(err => console.log(err.response))
}

export function getDoctorList(payload, options) {
	const params = new URLSearchParams(payload).toString()
	return Axios.get(ApiConstants.BASE_URL + 'doctor/by-dispensary?' + params, options)
}

export function getStaffList(data, options) {
	const params = new URLSearchParams(data).toString()
	return Axios.get(ApiConstants.BASE_URL + 'dispensary/staff?' + params, options)
}
