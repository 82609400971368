import React, { Component } from 'react'
import S3Uploader from 'react-s3-uploader' // import the component
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Box, Container, Grid } from '@material-ui/core'
import { CameraAlt, CheckCircle } from '@material-ui/icons'

import * as doctorActions from '../doctor/redux/actions'
import FormControlsLayout from 'containers/forms/components/FormControlsLayout'
import NICPlaceholderCard from 'containers/forms/components/widgets/NICPlaceholderCard'
import Widgets from 'components/widgets'
import FormControlEnd from 'containers/forms/components/FormControlEnd'
// spacing = 1 (8px)
// rem = 1  (16px)
const styles = theme => ({
	root: {
		flexGrow: 1
	},

	leftBox: {
		// borderRight: '1px solid red',
		minHeight: '5rem',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(8),
		padding: '3rem 1rem'
	},
	rightBox: {
		minHeight: '5rem',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(8),
		padding: '3rem 1rem'
	},

	headingBox: {
		// border: '2px dotted green',
		margin: `${theme.spacing(5)}px ${theme.spacing(0)}px`,
		fontSize: 'clamp(18px,2.5vw,24px)',
		fontFamily: 'Roboto Bold ,sans-serif',
		color: '#0E2C39',
		[theme.breakpoints.down('xs')]: {
			fontSize: '16px'
		}
	},

	formEndBox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: `${theme.spacing(5)}px ${theme.spacing(0)}px`,
		padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`,

		[theme.breakpoints.down('sm')]: {
			padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`,
			margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`
		}
	}
})

class DoctorNicUploadWizard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			file: '',
			frontImage: null,
			backImage: null
		}
	}

	componentDidMount() {}

	handleNext = e => {
		e.preventDefault()
		//alert('upload into s3 bucket')
		// this.props.nextStep();
		// this.props.loginActions.refresh();
	}

	handleBack = () => {
		this.props.prevStep()
	}

	handleCancel = () => {
		this.props.exitForm()
	}

	handleSubmit = e => {
		e.preventDefault()
		//this.props.handleFormSubmit();
	}

	handleChange = e => {
		const file = URL.createObjectURL(e.target.files[0])
		const data = { src: file, file: e.target.files[0] }
		this.setState({ ...this.state, [e.target.name]: data })
	}

	uploadImagesToS3 = e => {
		e.preventDefault()
		const { frontImage, backImage } = this.state
		if (frontImage === null || backImage === null) {
			// TODO show error message
		} else {
			let images = {
				front: frontImage.file,
				back: backImage.file
			}
			this.props.uploadImagetoS3(images)
		}
	}

	render() {
		const { classes } = this.props
		const { frontImage, backImage } = this.state

		return (
			<FormControlsLayout onSubmit={this.uploadImagesToS3}>
				<Grid container>
					<Grid item xs={12}>
						<Box
							component='div'
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							className={classes.headingBox}>
							National Identity Card
						</Box>
					</Grid>
					{/* end subheading block */}
					<Grid item xs={12}>
						<Grid container spacing={0} justifyContent='space-between'>
							<Grid item xs={6}>
								<Box
									component='div'
									display='flex'
									flexDirection='row'
									justifyContent='center'
									alignItems='center'>
									<NICPlaceholderCard
										fileId='file1'
										fileName='frontImage'
										fileData={frontImage}
										handleChange={this.handleChange}
										captionText='Front of NIC'
									/>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box
									component='div'
									display='flex'
									flexDirection='row'
									justifyContent='center'
									alignItems='center'>
									<NICPlaceholderCard
										fileId='file2'
										fileName='backImage'
										fileData={backImage}
										handleChange={this.handleChange}
										captionText='Back of NIC'
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>

					{/*  FORM_END_SECTION */}
					<Grid item xs={12}>
						<FormControlEnd
							btnType='submit'
							label='Next'
							isCheckbox={false}
							disabled={false}
							onChange={() => {}}
							onClick={this.handleNext}
						/>
					</Grid>
				</Grid>
			</FormControlsLayout>
		)
	}
}

function mapStateToProps(state) {
	return {
		doctorState: state.doctorState
	}
}

function mapDispatchToProps(dispatch) {
	return {
		doctorActions: bindActionCreators(doctorActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DoctorNicUploadWizard))
