import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Box, Container } from '@material-ui/core'

import AuthForm from 'containers/forms/auths/components/AuthForm'
import * as loginActions from './redux/actions'
import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { AWSStatusCode } from 'config/AWSStatusCode'
import { SET_LOGIN } from './AuthEvents'
import FormOTPWizardLayout from '../components/shared/FormOTPWizardLayout'

const styles = theme => ({
	root: {
		flex: 1,
		[theme.breakpoints.between('md', 'lg')]: {
			zoom: '0.75'
		}
	},
	formBox: {
		margin: theme.spacing(1),
		minHeight: 'calc(100vh - 15rem)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start'
	},
	loginForm: {
		width: '70%',
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		}
	},
	stickyBox: {
		position: 'absolute',
		right: '10px',
		bottom: '100px'
	}
})

class LoginPage extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		const { loginActions } = this.props
		loginActions.updateFormStep(1)
		if (this.props.loginState.loggedIn) {
			const { history } = this.props
			history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { history } = this.props
		const prevResponse = prevProps.loginState.dispensaryOwnerSignInResponse
		const currentResponse = this.props.loginState.dispensaryOwnerSignInResponse

		if (currentResponse !== prevResponse) {
			if (currentResponse === null) {
			} else if (currentResponse.status === AWSStatusCode.SUCCESS) {
				history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
			}
		}

		const prevConfirmUserResponse = prevProps.dispensaryState.confirmDispensaryOwnerResponse
		const currentConfirmUserResponse = this.props.dispensaryState.confirmDispensaryOwnerResponse

		if (currentConfirmUserResponse.status === AWSStatusCode.SUCCESS) {
			if (currentConfirmUserResponse.uuid !== prevConfirmUserResponse.uuid) {
				if (currentConfirmUserResponse.form === 'FROM_VALIDATE') {
					// this.props.loginActions.updateFormStep(1)
					const { history } = this.props
					history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
				}
			}
		}
	}

	handleInputChange = e => {
		const { loginActions } = this.props
		const { name, value } = e.target
		loginActions.onChangeField(name, value)
	}

	handleSubmit = e => {
		e.preventDefault()
		const { loginState, loginActions } = this.props
		const { formInputs } = loginState

		let payload = {
			username: formInputs.email,
			password: formInputs.password,
			fromOwnerLoginPage: true
		}
		loginActions.dispensaryOwnerSignIn(payload)
		// const { history } = this.props;
		// history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
		// alert('submitting.......')
	}

	render() {
		const { classes, loginState } = this.props
		const { step, email, password } = loginState

		const loginValues = { email, password }
		switch (step) {
			case 1:
				return (
					<Box component='div'>
						<Container
							className={classes.root}
							maxWidth='md'
							disableGutters={false}
							component='div'>
							<Box component='div' className={classes.formBox}>
								<div className={classes.loginForm}>
									{/* SET_LOGIN | SET_EMAIL_AND_PASSWORD | SET_EMAIL */}

									<AuthForm
										formEvent={SET_LOGIN}
										data={loginValues}
										buttonLabel='Login'
										handleSubmit={this.handleSubmit}
										handleInputChange={this.handleInputChange}
									/>
								</div>
							</Box>
						</Container>
						{/* <span className={classes.stickyBox}>
                    <CallNowFooterSticky history={history} />
                </span> */}
					</Box>
				)

			case 2:
				return <FormOTPWizardLayout formType={'FROM_VALIDATE'} />
			default:
				return <Box>Error</Box>
		}
	}
}

function mapStateToProps(state) {
	return {
		loginState: state.authReducer,
		dispensaryState: state.dispensaryReducer
	}
}

function mapDispatchToProps(dispatch) {
	return {
		loginActions: bindActionCreators(loginActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(LoginPage))
