export const RESET_DOCTOR_STATE = 'RESET_DOCTOR_STATE'
export const SET_DOCTOR_FORM_FIELD_VALUE = 'SET_DOCTOR_FORM_FIELD_VALUE'
export const SET_DOCTOR_FORM_FIELD_HINT = 'SET_DOCTOR_FORM_FIELD_HINT'
export const UPDATE_DOCTOR_CURRENT_FORM_STEP = 'UPDATE_DOCTOR_CURRENT_FORM_STEP'
export const UPDATE_DOCTOR_CONDITION_AND_TERMS = 'UPDATE_DOCTOR_CONDITION_AND_TERMS'
export const RESET_DOCTOR_FORM_VALUES = 'RESET_DOCTOR_FORM_VALUES'
export const GET_DOCTOR_METADATA = 'GET_DOCTOR_METADATA'
export const SET_DOCTOR_METADATA = 'SET_DOCTOR_METADATA'

export const SUBMIT_OWNER_DOCTOR_TO_API = 'SUBMIT_OWNER_DOCTOR_TO_API'
export const SUBMIT_OWNER_DOCTOR_TO_API_RESPONSE = 'SUBMIT_OWNER_DOCTOR_TO_API_RESPONSE'
export const ASSIGN_DOCTOR_TO_DISPENSARY_REQUEST = 'ASSIGN_DOCTOR_TO_DISPENSARY_REQUEST'
export const ASSIGN_DOCTOR_TO_DISPENSARY_RESPONSE = 'ASSIGN_DOCTOR_TO_DISPENSARY_RESPONSE'
export const SEND_EMAIL_INVITATION_REQUEST = 'SEND_EMAIL_INVITATION_REQUEST'
export const SEND_EMAIL_INVITATION_RESPONSE = 'SEND_EMAIL_INVITATION_RESPONSE'

export const UPLOAD_IMAGES_TO_S3_REQUEST = 'UPLOAD_IMAGES_TO_S3_REQUEST'
export const UPLOAD_IMAGES_TO_S3_RESPONSE = 'UPLOAD_IMAGES_TO_S3_RESPONSE'

export const SET_TEMP_DISPENSARY = 'SET_TEMP_DISPENSARY'
export const CLEAR_TEMP_DISPENSARY = 'CLEAR_TEMP_DISPENSARY'

export const CREATE_AWS_DOCTOR_REQUEST = 'CREATE_AWS_DOCTOR_REQUEST'
export const CREATE_AWS_DOCTOR_RESPONSE = 'CREATE_AWS_DOCTOR_RESPONSE'
export const AWS_DOCTOR_CONFIRM_OTP_REQUEST = 'AWS_DOCTOR_CONFIRM_OTP_REQUEST'
export const AWS_DOCTOR_CONFIRM_OTP_RESPONSE = 'AWS_DOCTOR_CONFIRM_OTP_RESPONSE'
export const SUBMIT_DOCTOR_TO_API = 'SUBMIT_DOCTOR_TO_API'
export const SUBMIT_DOCTOR_TO_API_RESPONSE = 'SUBMIT_DOCTOR_TO_API_RESPONSE'

export const DOCTOR_SIGN_IN = 'DOCTOR_SIGN_IN'
export const DOCTOR_SIGN_IN_RESPONSE = 'DOCTOR_SIGN_IN_RESPONSE'

export const SET_DOCTOR_INFO_TO_FORM = 'SET_DOCTOR_INFO_TO_FORM'
export const UPDATE_DOCTOR_INFO_API_REQUEST = 'UPDATE_DOCTOR_INFO_API_REQUEST'
export const UPDATE_DOCTOR_INFO_API_RESPONSE = 'UPDATE_DOCTOR_INFO_API_RESPONSE'
