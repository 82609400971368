import { takeLatest } from 'redux-saga/effects'
import * as types from '../redux/types'
import {
	awsCreateDispensaryOwnerAccountSaga,
	awsConfirmUserSaga,
	createDispensarySaga,
	resendOtpSaga,
	updateDispensaryInfoSaga
} from './Sagas'

export const dispensarySagas = [
	takeLatest(types.AWS_CREATE_DISPENSARY_OWNER_ACCOUNT_REQUEST, awsCreateDispensaryOwnerAccountSaga),
	takeLatest(types.AWS_CONFIRM_OTP_REQUEST, awsConfirmUserSaga),
	takeLatest(types.CREATE_DISPENSARY_REQUEST, createDispensarySaga),
	takeLatest(types.RESEND_OTP_REQUEST, resendOtpSaga),
	takeLatest(types.UPDATE_DISPENSARY_INFO_REQUEST, updateDispensaryInfoSaga)
]
