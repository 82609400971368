import { all } from 'redux-saga/effects'
import { authSagas } from '../containers/forms/auths/saga'
import { dispensarySagas } from '../containers/forms/Dispensary/saga'
import { doctorSagas } from '../containers/forms/doctor/saga'
import { portalSagas } from 'containers/portalweb/saga'
import { contactUsSagas } from 'containers/forms/contactus/saga'
import { staffSagas } from 'containers/forms/Dispensary/staffSaga'
import { appointmentsSagas} from 'containers/forms/Kiosk/saga'
// import { profileSagas } from '../feature/MyProfile/sagas';

export default function* rootSaga() {
	yield all([
		...authSagas,
		...dispensarySagas,
		...doctorSagas,
		...portalSagas,
		...contactUsSagas,
		...staffSagas,
		...appointmentsSagas
		// ...appointmentsSagas,
		// ...profileSagas
	])
}
