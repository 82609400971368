import React, { useState, useEffect, Fragment } from 'react'
import { InputAdornment, TextField, IconButton } from '@material-ui/core'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const dartmouthGreenColor = '#2D7638'
const themeGreenColor = '#39CFBA'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		'& .MuiInputLabel-outlined ': {
			fontFamily: 'Roboto light,sans-serif'
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: '0px',
			fontFamily: 'Roboto Medium,sans-serif',
			'& fieldset': {
				//borderColor: themeGreenColor,
			},
			'&:hover fieldset': {
				//borderColor: themeGreenColor,
			},
			'&.Mui-focused fieldset': {
				//borderColor: mingColor, * when focus feild
			}
		}
	}
}))

export default function InputPasswordText(props) {
	const { id, name, label, value, error = null, lblWidth, onChange, disabled = false } = props
	const classes = useStyles()

	const [showPassword, setshowPassword] = useState(false)

	const handleMouseDownPassword = event => {
		event.preventDefault()
	}

	const handleClickShowPassword = () => {
		setshowPassword(!showPassword)
	}

	return (
		<Fragment>
			{/* <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <OutlinedInput
                    id={id}
                    type={showPassword ? 'text' : 'password'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    labelWidth={lblWidth}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl> */}
			<TextField
				className={classes.root}
				variant='outlined'
				size='medium'
				label={label}
				type={showPassword ? 'text' : 'password'}
				name={name}
				disabled={disabled}
				value={value}
				onChange={onChange}
				{...(error && { error: true, helperText: error })}
				InputProps={{
					endAdornment: (
						<InputAdornment position='start'>
							<IconButton
								aria-label='toggle password visibility'
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge='end'>
								{showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
		</Fragment>
	)
}
