import React, { useState, useEffect, Fragment } from 'react'
import { Box, Container, Grid } from '@material-ui/core'
import { CameraAlt, CheckCircle } from '@material-ui/icons'
import clsx from 'clsx'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'

import upload from '../../../../images/test_upload.jpg'

const useStyles = makeStyles(theme => ({
	nicBoxContainer: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(6),
		padding: '1rem calc(1rem - 0.5rem)'
	},

	leftBorder: {
		borderRight: '1px solid #ddd',
		[theme.breakpoints.down('xs')]: {
			borderRight: 'none'
		}
	},

	nicIcon: {
		textAlign: 'center',
		color: '#39CFBA',
		fontSize: 'clamp(3.5rem ,calc(6vw + 1rem), 6rem)',
		[theme.breakpoints.between('md', 'lg')]: {
			fontSize: 'clamp(3.5rem ,calc(7vw + 1rem), 6rem)'
		}
	},
	cameraMuteIcon: {
		fontSize: 'clamp(1rem ,calc(1.5rem + 1rem), 1.5rem)',
		color: '#39CFBA'
	},

	uploadImageCaption: {
		textAlign: 'center'
	},

	uploadCaptionMuteText: {
		paddingTop: theme.spacing(1),
		textAlign: 'center',
		fontSize: 'clamp(12px ,calc(2vw - 1px), 14px)',
		color: '#0E2C39',
		fontFamily: 'Roboto Light ,sans-serif',
		color: '#0E2C39',
		[theme.breakpoints.between('md', 'lg')]: {
			fontSize: '1rem'
		}
	},
	isBackImgBox: {
		backgroundColor: '#FFFFFF !important',
		opacity: '0.5 !important'
	},
	uploadImageSpan: {
		//width: 'calc(100vw - calc(100vw * 0.83))',
		width: '60%',

		minHeight: 'calc(100vh - calc(100vh * 0.70))',
		[theme.breakpoints.between('md', 'lg')]: {
			minHeight: 'calc(100vh - calc(100vh * 0.60))'
		},
		[theme.breakpoints.up('xl')]: {
			width: '55%',
			minHeight: 'calc(100vh - calc(100vh * 0.70))'
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			minHeight: 'calc(100vh - calc(100vh * 0.75))'
		}
	},

	selectedImageBox: {
		width: '60%',
		minHeight: 'calc(100vh - calc(100vh * 0.70))',
		objectFit: 'cover',
		[theme.breakpoints.between('md', 'lg')]: {
			minHeight: 'calc(100vh - calc(100vh * 0.60))'
		},
		[theme.breakpoints.up('xl')]: {
			width: '55%',
			minHeight: 'calc(100vh - calc(100vh * 0.70))'
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			minHeight: 'calc(100vh - calc(100vh * 0.75))'
		}
	},
	FuploadPhoto: {
		objectFit: 'cover',
		width: '100%',
		height: '100%'
	}
}))

const NICPlaceholderCard = props => {
	const classes = useStyles()
	const theme = useTheme()
	const { fileId, fileName, handleChange, captionText, fileData } = props

	return (
		<label
			className={`${clsx(classes.tmp, {
				[classes.leftBorder]: fileId === 'file1'
			})} custom-file-upload`}>
			<input type='file' accept='/*' id={fileId} name={fileName} onChange={handleChange} />

			{fileData === null ? (
				<Box
					component='div'
					display='flex'
					alignSelf='center'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					className={`${clsx(classes.uploadImageSpan)} upload_image_span`}>
					<CameraAlt className={classes.nicIcon} />
					<div className={`${classes.uploadImageCaption} upload_image_caption`}>{captionText}</div>
				</Box>
			) : (
				<Box
					component='div'
					display='flex'
					alignSelf='center'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					className={`${classes.selectedImageBox} selected_image_box`}>
					{/* TODO fileData.file */}
					<img src={fileData.src} className={classes.FuploadPhoto} />
				</Box>
			)}
			{/* mute Text */}

			<Box
				component='div'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
				className={classes.uploadCaptionMuteText}>
				{fileData ? null : <span>Upload image or take a picture</span>}
				{fileData ? <CheckCircle className={classes.cameraMuteIcon} /> : null}
			</Box>
		</label>
	)
}

export default NICPlaceholderCard
