import Amplify from 'aws-amplify'
import { AwsConstants } from 'constants/AwsConstants'

Amplify.configure({
	Auth: {
		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: true,

		// REQUIRED - Amazon Cognito Region
		region: AwsConstants.cognito.REGION,

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: AwsConstants.cognito.USER_POOL_ID,

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: AwsConstants.cognito.APP_CLIENT_ID,
		identityPoolId: AwsConstants.cognito.IDENTITY_POOL_ID
	},
	Storage: {
		AWSS3: {
			level: AwsConstants.s3.ACCESS_LEVEL,
			bucket: AwsConstants.s3.BUCKET,
			region: AwsConstants.s3.REGION,
			identityPoolId: AwsConstants.s3.IDENTITY_POOL_ID
		}
	},
	aws_appsync_graphqlEndpoint: AwsConstants.appsync.GRAPHQL_ENDPOINT,
    aws_appsync_region: AwsConstants.appsync.REGION,
    aws_appsync_authenticationType: AwsConstants.appsync.AUTHENTICATIONTYPE,
})
