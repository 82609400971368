import React from 'react'
import ReactDOM from 'react-dom'

import './config/aws-amplify'

// import { AppContainer } from 'react-hot-loader';
import { configureStore, history } from 'store/configureStore'
import Root from 'components/Root'

const { store, persistor } = configureStore() || {}

if (process.env.NODE_ENV.trim() === 'production') {
	console.log = () => {}
}

ReactDOM.render(
	<Root store={store} history={history} persistor={persistor} />,
	document.getElementById('root')
)

// <AppContainer></AppContainer>,
