import * as types from './types'

export function resetDoctorState() {
	return {
		type: types.RESET_DOCTOR_STATE
	}
}

export function onChangeField(fieldname, value) {
	return {
		type: types.SET_DOCTOR_FORM_FIELD_VALUE,
		payload: { fieldname, value }
	}
}

export function onChangeHint(fieldname, value) {
	return {
		type: types.SET_DOCTOR_FORM_FIELD_HINT,
		payload: { fieldname, value }
	}
}

export function updateFormStep(step) {
	return {
		type: types.UPDATE_DOCTOR_CURRENT_FORM_STEP,
		payload: step
	}
}

export function updateTermAndCondition(status) {
	return {
		type: types.UPDATE_DOCTOR_CONDITION_AND_TERMS,
		payload: status
	}
}

export function resetForm() {
	return {
		type: types.RESET_DOCTOR_FORM_VALUES,
		payload: null
	}
}

export function getDoctorMetadata() {
	return {
		type: types.GET_DOCTOR_METADATA
	}
}

export function setDoctorMetadata(payload) {
	return {
		type: types.SET_DOCTOR_METADATA,
		payload
	}
}

export function submitOwnerDoctorToApi(payload) {
	return {
		type: types.SUBMIT_OWNER_DOCTOR_TO_API,
		payload
	}
}

export function submitOwnerDoctorToApiResponse(payload) {
	return {
		type: types.SUBMIT_OWNER_DOCTOR_TO_API_RESPONSE,
		payload
	}
}

export function sendEmailInvitation(payload) {
	return {
		type: types.SEND_EMAIL_INVITATION_REQUEST,
		payload
	}
}

export function setEmailInvitationResponse(payload) {
	return {
		type: types.SEND_EMAIL_INVITATION_RESPONSE,
		payload
	}
}

export function assignDoctorToDispensaryRequest(payload) {
	return {
		type: types.ASSIGN_DOCTOR_TO_DISPENSARY_REQUEST,
		payload
	}
}

export function assignDoctorToDispensaryResponse(payload) {
	return {
		type: types.ASSIGN_DOCTOR_TO_DISPENSARY_RESPONSE,
		payload
	}
}

export function uploadImagesToS3(payload) {
	return {
		type: types.UPLOAD_IMAGES_TO_S3_REQUEST,
		payload
	}
}

export function uploadImagesToS3Response(payload) {
	return {
		type: types.UPLOAD_IMAGES_TO_S3_RESPONSE,
		payload
	}
}

//invitation
export function awsDoctorRegistration(payload) {
	return {
		type: types.CREATE_AWS_DOCTOR_REQUEST,
		payload
	}
}

export function awsDoctorRegistrationResponse(payload) {
	return {
		type: types.CREATE_AWS_DOCTOR_RESPONSE,
		payload
	}
}

export function awsConfirmUserRequest(payload) {
	return {
		type: types.AWS_DOCTOR_CONFIRM_OTP_REQUEST,
		payload
	}
}

export function awsConfirmUserResponse(payload) {
	return {
		type: types.AWS_DOCTOR_CONFIRM_OTP_RESPONSE,
		payload
	}
}

export function submitDoctorToApi(payload) {
	return {
		type: types.SUBMIT_DOCTOR_TO_API,
		payload
	}
}

export function submitDoctorToApiResponse(payload) {
	return {
		type: types.SUBMIT_DOCTOR_TO_API_RESPONSE,
		payload
	}
}

export function doctorSignIn(payload) {
	return {
		type: types.DOCTOR_SIGN_IN,
		payload
	}
}

export function doctorSignInResponse(payload) {
	return {
		type: types.DOCTOR_SIGN_IN_RESPONSE,
		payload
	}
}

export function setDoctorInfoToForm(payload) {
	return {
		type: types.SET_DOCTOR_INFO_TO_FORM,
		payload
	}
}

export function updateDoctorInfoApi(payload) {
	return {
		type: types.UPDATE_DOCTOR_INFO_API_REQUEST,
		payload
	}
}
