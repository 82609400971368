import { put, call } from 'redux-saga/effects'
import * as actions from '../redux/actions'
import * as appActions from '../../../../store/AppStore/appActions'
import { submitContactData } from '../api'
import { ErrorConstants } from 'constants/ErrorConstants'

export function* submitContactUsSaga(action) {
	yield put(appActions.showLoading())
	const payload = action.payload
	try {
		const response = yield call(submitContactData, payload)
		if (response.status === 200) {
			yield put(actions.responseContactUsData(response.data))
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(appActions.showError({ show: true, data: ErrorConstants.CONTACT_US.FAILED }))
	}
}
