//import _ from 'lodash';
const Validators = function () {
	const required = feildValue => {
		if (!feildValue || feildValue === undefined) {
			return 'Required'
		}
	}

	const validateEmail = value => {
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			return 'Invalid email Address'
		}
	}

	const minLength = (value, min = null) => {
		if (value.length < min) {
			return `Min length should be ${min}`
		}
	}

	const maxLength = (value, max = null) => {
		if (value.length > max) {
			return `Max length should be ${max}`
		}
	}

	const compareToEmail = (repeat, compareTo) => {
		if (repeat !== compareTo) {
			return 'Email should be matched.'
		}
	}

	const validateOption = value => {
		if (value.length === 0) {
			return 'Please select a valid option.'
		}
	}

	return {
		validateEmail,
		required,
		minLength,
		compareToEmail,
		validateOption,
		maxLength
	}
}

export default Validators()
