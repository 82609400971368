import { takeLatest } from 'redux-saga/effects'
import * as types from '../redux/staffTypes'
import {
	createStaffMemberAwsSaga,
	assignStaffToDispensarySaga,
	updateStaffPasswordSaga,
	updateStaffMemberSaga,
	submitStaffPasswordResetSaga,
	confirmStaffUserAccountSaga
} from './Sagas'

export const staffSagas = [
	takeLatest(types.CREATE_STAFF_MEMBER_REQUEST, createStaffMemberAwsSaga),
	takeLatest(types.SUBMIT_DISPENSARY_STAFF_API_REQUEST, assignStaffToDispensarySaga),
	takeLatest(types.UPDATE_STAFF_PASSWORD, updateStaffPasswordSaga),
	takeLatest(types.UPDATE_DISPENSARY_STAFF, updateStaffMemberSaga),
	takeLatest(types.SUBMIT_STAFF_PASSWORD_REQUEST, submitStaffPasswordResetSaga),
	takeLatest(types.CONFIRM_STAFF_USER_REQUEST,confirmStaffUserAccountSaga)
]
