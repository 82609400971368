import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
// import { History } from 'history';
// import { reducer as formReducer } from 'redux-form';
import appReducer from './AppStore/appReducer'
import authReducer from '../containers/forms/auths/redux/reducer'
import dispensaryReducer from '../containers/forms/Dispensary/redux/reducer'
import doctorReducer from '../containers/forms/doctor/redux/reducer'
import portalReducer from '../containers/portalweb/redux/reducer'
import contactReducer from '../containers/forms/contactus/redux/reducer'
import staffReducer from '../containers/forms/Dispensary/redux/staffReducer'
import resetPasswordReducer from '../containers/forms/auths/redux/reducerResetPassword'
import appointmentsReducer from '../containers/forms/Kiosk/redux/reducer'
// import doctorState from './doctorState';

const rootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		appReducer,
		authReducer,
		dispensaryReducer,
		doctorReducer,
		portalReducer,
		contactReducer,
		staffReducer,
		resetPasswordReducer,
		appointmentsReducer

	})

export default rootReducer
