export const AwsConstants = {
	cognito: {
		REGION: process.env.REACT_APP_REGION,
		USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
		APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
		IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID
	},
	s3: {
		ACCESS_LEVEL: process.env.REACT_APP_ACCESS_LEVEL,
		BUCKET: process.env.REACT_APP_BUCKET,
		REGION: process.env.REACT_APP_REGION,
		IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID
	},
	appsync: {
		GRAPHQL_ENDPOINT: process.env.REACT_APP_GRAPHQL_ENDPOINT,
		REGION: process.env.REACT_APP_REGION,
		AUTHENTICATIONTYPE: process.env.REACT_APP_AUTHENTICATIONTYPE
	}
}
