import React, { useState, useEffect, Fragment } from 'react'
import { Box, Container, Grid, FormControl, OutlinedInput, TextField } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
		//border: '1px dotted #ccc',
		//borderLeft: '1px dotted #ccc'
	},
	formContentBox: {
		padding: '0em 0em'
	},
	formTop: {},
	formBottom: {
		textAlign: 'center',
		fontSize: '14px'
	},
	formsData: {
		boxSizing: 'border-box',
		'& .MuiFormControl-root': {
			width: '100%',
			marginBottom: theme.spacing(4)
		}
	}
}))

const FormControlsLayout = props => {
	const classes = useStyles()
	const theme = useTheme()
	const { children, ...rest } = props

	return (
		<Box component='div'>
			<Container className={classes.root} maxWidth='md' disableGutters={false} component='div'>
				<Grid container className={classes.formContentBox}>
					<Grid item xs={12}>
						<form autoComplete='off' className={classes.formsData} {...rest}>
							{children}
						</form>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default FormControlsLayout
