export const SET_LOGIN_FORM_FIELD_VALUE = 'SET_LOGIN_FORM_FIELD_VALUE'
export const LOGIN_DISPENSARY_PORTAL = 'LOGIN_DISPENSARY_PORTAL'
export const RESET_LOGIN_FORM_VALUES = 'RESET_LOGIN_FORM_VALUES'
export const LOAD_USER_AUTH_VALUE = 'LOAD_USER_AUTH_VALUE'
export const CHECK_LOGIN_STATUS = 'CHECK_LOGIN_STATUS'
export const SET_LOGIN_STATE = 'SET_LOGIN_STATE'
export const UPDATE_CURRENT_RESET_FORM_STEP = 'UPDATE_CURRENT_RESET_FORM_STEP'

export const RESET_PASSWORD_AWS_REQUEST = 'RESET_PASSWORD_AWS_REQUEST'
export const SET_OTP_REQUEST_RESPONSE = 'SET_OTP_REQUEST_RESPONSE'

export const RESET = 'RESET'

export const SET_OTP = 'SET_OTP'
export const SUBMIT_PASSWORD_RESET = 'SUBMIT_PASSWORD_RESET'
