import React, { useState, useEffect } from 'react'
import { Box, Container, Grid, FormControl, OutlinedInput, TextField } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import Widgets from 'components/widgets'
import Controls from 'containers/forms/components/controls'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		marginTop: theme.spacing(5),
		padding: theme.spacing(2),
		// borderTop: '1px dotted red',
		[theme.breakpoints.down('sm')]: {
			padding: `${theme.spacing(0)}rem ${theme.spacing(0)}rem`,
			margin: `${theme.spacing(-0.2)}rem ${theme.spacing(0)}rem ${theme.spacing(
				0.2
			)}rem ${theme.spacing(0)}rem`
		}
	},

	formConditionTermBox: {
		//border: '1px dotted red',
		padding: `${theme.spacing(0.01)}rem ${theme.spacing(0.3)}rem`,
		[theme.breakpoints.down('sm')]: {
			padding: `${theme.spacing(0)}rem ${theme.spacing(0)}rem`,
			margin: `${theme.spacing(0.1)}rem ${theme.spacing(0)}rem`
		}
	},

	formActionBox: {
		// border: '1px dotted #001',
		padding: `${theme.spacing(0.05)}rem ${theme.spacing(0.3)}rem`,
		[theme.breakpoints.down('sm')]: {
			padding: `${theme.spacing(0)}rem ${theme.spacing(0)}rem`,
			margin: `${theme.spacing(0)}rem ${theme.spacing(0)}rem`
		}
	}
}))

const FormControlEnd = props => {
	const classes = useStyles()
	const theme = useTheme()
	const { isCheckbox = false, disabled = true, btnType, label, conditionTerms, onPressed, onChange } = props

	return (
		<Box
			className={classes.root}
			component='div'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'>
			{isCheckbox && (
				<div className={classes.formConditionTermBox}>
					{/* <Controls.CheckBox
                        label="By creating an account you agree to our Terms of Service and Privacy Policy."
                        name="conditionTerms"
                        value={conditionTerms}
                        onChange={onChange}
                    /> */}
					<Controls.CheckBoxNative
						name='conditionTerms'
						value={conditionTerms}
						onChange={onChange}
					/>
				</div>
			)}

			<div className={classes.formActionBox}>
				<Widgets.ButtonBox
					type={btnType}
					disabled={disabled}
					label={label}
					variant='contained'
					color='primary'
					size='large'
					onClick={onPressed}
				/>
			</div>
		</Box>
	)
}

export default FormControlEnd
