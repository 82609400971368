/**
 * FYI: This is a demo for class components
 * To use simple-keyboard with React Hooks / Function components, check out:
 * https://simple-keyboard.com/react/demo/hooks
 */
import React, { Component } from "react";
import Keyboard from "react-simple-keyboard";
import { makeStyles } from '@material-ui/core/styles'
import "react-simple-keyboard/build/css/index.css";
import "./index.css"
import Grid from '@material-ui/core/Grid'
import {
  Box,
  Container,
  Toolbar,
  AppBar, Typography,
  Button
} from '@material-ui/core'
import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { withRouter, useHistory } from 'react-router-dom';
import { createTheme } from '@material-ui/core/styles';


class DefaultKeyboard extends Component {

  constructor(props) {
    super(props)
    const {onClickEnter} = props
  }
  state = {
      layoutName: "default",
      input: ""
    };

    commonKeyboardOptions = {
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
    };

    keyboardOptions = {
      ...this.commonKeyboardOptions,

      display: {
        "{backspace}": "⌫  Delete",
        "{enter}": "Next >>",
      }
    };
  
    onChange = input => {
      this.setState({ input });
    };
  
    onKeyPress = button => {
      if (button === "{enter}" && this.state.input!=="" && this.state.input.length>2) {
        this.props.onClickEnter(this.state.input)
      };

    };
  
    onChangeInput = event => {
      const input = event.target.value;
      this.setState({ input });
      this.keyboard.setInput(input);
    };
  
    render() {
      const { classes } = this.props;
      return (
        <div>

          <Grid item xs={12} class="labelRow">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                  <Grid container style={{justifyContent: 'flex-end'}}>
                    <Grid item  class="labelWrapper">
                      <Typography class="formLabel">Enter First Name</Typography>
                      <Typography class="formLabelSinhala">ඔබගේ මුල් නම</Typography>
                    </Grid>
                  </Grid>
                  
              </Grid>
              <Grid item xs={12} sm={8}>
                  {<input inputmode='none'
                      value={this.state.input}
                      placeholder={"........................."}
                      onChange={this.onChangeInput}
                  /> 
                  }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Keyboard
              keyboardRef={r => (this.keyboard = r)}
              layoutName={this.state.layoutName}
              theme="hg-theme-default simple-keyboard my-theme hg-button default-button"
              layout={{
                default: [
                "Q W E R T Y U I O P",
                "/ A S D F G H J K L /",
                "* Z X C V B N M - *",
                "+ {backspace} {enter} +",
                ]}}
                buttonTheme={[
                  {
                    class: "default-button",
                    buttons: "Q W E R T Y U I O P A S D F G H J K L Z X C V B N M -"
                  },
                  {
                    class: "delete-button",
                    buttons: "{backspace}"
                  },
                  {
                    class: "next-button",
                    buttons: "{enter}"
                  },
                  {
                    class: "hide-button1",
                    buttons: "/"
                  },
                  {
                    class: "hide-button2",
                    buttons: "*"
                  },
                  {
                    class: "hide-button3",
                    buttons: "+"
                  },

              ]
              }
              {...this.keyboardOptions}

            />
          </Grid>
        </div>

    );
  }
}

//render(<App />, document.getElementById("root"));



export default DefaultKeyboard


