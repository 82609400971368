import InputText from './Input'
// import InputGroupText from './InputGroup';
import Select from './Select'
import CheckBox from './CheckBox'
import CheckBoxNative from './CheckBoxNative'
import TextArea from './TextArea'
import InputPasswordText from './InputPasswordGroup'
import GoogleSearchInputBox from './GoogleSearchInputBox'
import MuiltInputBox from './MuiltInputBox'

const Controls = {
	InputText,
	InputPasswordText,
	Select,
	TextArea,
	CheckBox,
	GoogleSearchInputBox,
	MuiltInputBox,
	CheckBoxNative
}

export default Controls
