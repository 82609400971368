import * as types from './types'

export function onChangeField(fieldname, value) {
	return {
		type: types.SET_CONTACT_FORM_FIELD_VALUE,
		payload: { fieldname, value }
	}
}

export function updateFormStep(step) {
	return {
		type: types.UPDATE_CONTACT_CURRENT_FORM_STEP,
		payload: step
	}
}

export function resetForm() {
	return {
		type: types.RESET_CONTACT_FORM_VALUES
	}
}

export function submitContactUsData(payload) {
	return {
		type: types.SUBMIT_CONTACT_MESSAGE,
		payload
	}
}

export function responseContactUsData(payload) {
	return {
		type: types.CONTACT_US_RESPONSE,
		payload
	}
}
