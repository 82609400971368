import * as types from './typesResetPassword'

export function onChangeField(fieldname, value) {
	return {
		type: types.SET_LOGIN_FORM_FIELD_VALUE,
		payload: { fieldname, value }
	}
}

export function updateResetFormStep(step) {
	return {
		type: types.UPDATE_CURRENT_RESET_FORM_STEP,
		payload: step
	}
}

export function resetForm() {
	return {
		type: types.RESET_LOGIN_FORM_VALUES,
		payload: null
	}
}

export function reset() {
	return { type: types.RESET }
}

export function setLoginState(payload) {
	return {
		type: types.SET_LOGIN_STATE,
		payload
	}
}

export function requestOtptoReset(payload) {
	return {
		type: types.RESET_PASSWORD_AWS_REQUEST,
		payload
	}
}

export function otptoResetResponse(payload) {
	return {
		type: types.SET_OTP_REQUEST_RESPONSE,
		payload
	}
}

export function setOtpValue(payload) {
	return {
		type: types.SET_OTP,
		payload
	}
}

export function submitResetPassword() {
	return {
		type: types.SUBMIT_PASSWORD_RESET
	}
}
