import { put, call, select } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import * as actions from '../redux/actions'
import * as appActions from '../../../store/AppStore/appActions'
import * as authActions from '../../forms/auths/redux/actions'
import { getDispensaryData, getDoctorDetails, getDoctorList, getStaffList } from '../api'
import { dateToday } from 'utils/Times'
import { AWSStatusCode } from 'config/AWSStatusCode'
import { goToStep } from 'containers/forms/Dispensary/DispensaryLayout'
import { v4 as uuidv4 } from 'uuid'
import { ErrorConstants } from 'constants/ErrorConstants'

export function* getDispensaryDetailsSaga() {
	yield put(appActions.showLoading())
	try {
		const userData = yield Auth.currentUserInfo()

		if (userData.attributes['custom:role'] === 'doctor') {
			yield put(authActions.signOut())
			yield put(appActions.hideLoading())
			yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.DOCTOR_LOGIN }))
		} else if (userData.attributes['custom:role'] === 'staff') {
			yield put(authActions.signOut())
			yield put(appActions.hideLoading())
			yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.STAFF_LOGIN }))
		} else {
			const user = yield Auth.currentAuthenticatedUser()
			const token = user.signInUserSession.idToken.jwtToken
			const options = {
				headers: {
					Authorization: token
				}
			}
			const dispensaryResponse = yield call(getDispensaryData, userData.attributes.email, options)

			let dispensaryDetails = null

			if (dispensaryResponse.status === 200) {
				dispensaryResponse.data.forEach(item => {
					if (item.geo_location) {
						dispensaryDetails = item
					}
				})
			}


			if (dispensaryDetails === null) {
				const payload = {
					uuid: uuidv4(),
					status: AWSStatusCode.SUCCESS,
					data: {},
					action: goToStep.DISPENSARY_DETAILS_FORM
				}
				yield put(actions.setDispensaryDetails(payload))
			} else {
				const payload = {
					uuid: uuidv4(),
					status: AWSStatusCode.SUCCESS,
					data: dispensaryDetails
				}
				yield put(actions.setDispensaryDetails(payload))
			}
			yield put(appActions.hideLoading())
		}
	} catch (error) {
		yield put(appActions.hideLoading())
	}
}

export function* getDoctorListSaga() {
	// TODO SHOW LOADER
	yield put(appActions.showLoading())
	try {
		const userData = yield Auth.currentUserInfo()
		if (userData) {
			const params = {
				dispensary_id: userData.attributes.email,
				date: dateToday(new Date().toLocaleDateString())
				// date: '2022-03-29'
			}
			const user = yield Auth.currentAuthenticatedUser()
			const token = user.signInUserSession.idToken.jwtToken
			const options = {
				headers: {
					Authorization: token
				}
			}
			const doctorList = yield call(getDoctorList, params, options)
			if (doctorList.status === 200) {
				yield put(actions.setDoctorList(doctorList.data))
			}
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
	}
}

export function* getStaffListSaga() {
	// TODO SHOW LOADER
	yield put(appActions.showLoading())
	try {
		const userData = yield Auth.currentUserInfo()
		const params = {
			dispensary_id: userData.attributes.email
		}

		const user = yield Auth.currentAuthenticatedUser()
		const token = user.signInUserSession.idToken.jwtToken
		const options = {
			headers: {
				Authorization: token
			}
		}
		const staffList = yield call(getStaffList, params, options)
		if (staffList.status === 200) {
			yield put(actions.setStaffList(staffList.data))
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
	}
}
