import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { Facebook } from 'react-spinners-css'
import PropTypes from 'prop-types'
Modal.setAppElement('*')

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)'
	}
}

class SendSmsLoader extends React.Component {
	//static defaultProps
	//static propTypes
	render() {
		const { sendSmsloading } = this.props
		return (
			<Modal isOpen={sendSmsloading} style={customStyles}>
				<div className='loader_box'>
					<Facebook color='#159782' />
					<span className='loader_text'>Sending SMS ...</span>
				</div>
			</Modal>
		)
	}
}

// Loader.defaultProps = {
//     loading: false
// }

// Loader.propTypes = {
//     loading: PropTypes.bool
// }

const mapStateToProps = state => {
	return {
		sendSmsloading: state.appReducer.sendSmsloading
	}
}

export default connect(mapStateToProps)(SendSmsLoader)
