export const APPOINTMENTS_REDUCER_RESET = 'APPOINTMENTS_REDUCER_RESET';

export const REQUEST_APPOINTMENTS = 'REQUEST_APPOINTMENTS';
export const RESPONSE_APPOINTMENTS = 'RESPONSE_APPOINTMENTS';

export const REQUEST_SEARCH_PATIENT = 'REQUEST_SEARCH_PATIENT';
export const RESPONSE_SEARCH_PATIENT = 'RESPONSE_SEARCH_PATIENT';

export const CHANGE_SELECTED_PATIENT = 'CHANGE_SELECTED_PATIENT';

export const REQUEST_ADD_NEW_PATIENT = 'REQUEST_ADD_NEW_PATIENT';
export const RESPONSE_ADD_NEW_PATIENT = 'RESPONSE_ADD_NEW_PATIENT';

export const REQUEST_BOOK_APPOINTMENT = 'REQUEST_BOOK_APPOINTMENT';
export const RESPONSE_BOOK_APPOINTMENT = 'RESPONSE_BOOK_APPOINTMENT';

export const REQUEST_APPOINTMENT_STATUS_UPDATE =
    'REQUEST_APPOINTMENT_STATUS_UPDATE';
export const RESPONSE_APPOINTMENT_STATUS_UPDATE =
    'RESPONSE_APPOINTMENT_STATUS_UPDATE';

export const REQUEST_CANCEL_APPOINTMENT = 'REQUEST_CANCEL_APPOINTMENT';
export const RESPONSE_CANCEL_APPOINTMENT = 'RESPONSE_CANCEL_APPOINTMENT';

export const UPDATE_MODIFY_SLOT = 'UPDATE_MODIFY_SLOT';

export const UPDATE_BOOKED_SLOT = 'UPDATE_BOOKED_SLOT'; //triggers when outsider is booked a slot

export const REQUEST_DOCTORS_BY_DISPENSARY = 'REQUEST_DOCTORS_BY_DISPENSARY';
export const RESPONSE_DOCTORS_BY_DISPENSARY = 'RESPONSE_DOCTORS_BY_DISPENSARY';

export const UPDATE_DOCTOR_LIST = 'UPDATE_DOCTOR_LIST';
export const NAVIGATE_TO_KIOSK = 'NAVIGATE_TO_KIOSK';
export const NAVIGATE_TO_KIOSK_MOBILE = 'NAVIGATE_TO_KIOSK_MOBILE';
export const KIOSK_FIRST_NAME = 'KIOSK_FIRST_NAME';


