import Axios from 'axios'
import { ApiConstants } from 'constants/ApiConstants'

export function appointments(data) {
	return Axios.post(ApiConstants.BASE_URL + 'appointment', data)
}

export function getAppointmentByPatientID(data) {
	return Axios.post(ApiConstants.BASE_URL + 'patient/appointment/get-by-patient', data)
}

export function cancelAppointment(data) {
	return Axios.post(ApiConstants.BASE_URL + 'appointment/cancel', data)
}

export function bookAppointment(paramsData, data, options) {

	const params = new URLSearchParams(paramsData).toString()
	return Axios.post(ApiConstants.BASE_URL + 'appointment/kiosk?'+ params, data, options)
    // return Api(ApiEndpoint.APPOINTMENT, Method.POST, params, body);
}

export function requestAppointments(data, options) {
	const params = new URLSearchParams(data).toString()
	return Axios.get(ApiConstants.BASE_URL + 'appointment/get-by-dispensary?' + params, options)
}

export function getDoctorsByDispensary(data, options) {
	const params = new URLSearchParams(data).toString()
	return Axios.get(ApiConstants.BASE_URL + 'doctor/by-dispensary?' + params, options)
}

export function addNewPatient(body, options) {
	return Axios.post(ApiConstants.BASE_URL + 'doctor/patient', body, options)
}


