import Moment from 'moment';


const SEVER_DATE_PATTERN = 'YYYY-MM-DD';
const DAY_FORMAT_PATTERN = 'dddd';
const SEVER_TIME_PATTERN = 'LT';

export function getCurrentDate() {
    return Moment().format(SEVER_DATE_PATTERN);
}

export function dayInText(value) {
    if (value) {
        return Moment(value).format(DAY_FORMAT_PATTERN).toLowerCase();
    }
    return '';
}

export function serverDateToString(value) {
    if (value) {
        return Moment(value).format(SEVER_DATE_PATTERN);
    }
    return '';
}

export function serverDateToTime(value) {
    if (value) {
        return Moment(value).format(SEVER_TIME_PATTERN);
    }
    return '';
}


const getSlotsWithUpdate = (oldSlots , updatedSlot) =>{
    if(!updatedSlot) return oldSlots

    const index = oldSlots.findIndex(os => os.slot_id === updatedSlot.slot_id)
    if (index >= 0) {
        const numSlots = oldSlots.length
        if (index == 0) {
            const newSlots = [updatedSlot, ...oldSlots.slice(1)]
            return  newSlots
        } else if (index == oldSlots.length - 1) {
            const newSlots = [...oldSlots.slice(0, numSlots - 1), updatedSlot]
            return newSlots
        } else {
            const newSlots = [...oldSlots.slice(0, index), updatedSlot, ...oldSlots.slice(index + 1)]
            return newSlots
        }
    }

    return oldSlots
}

export const getUpdatedAppoiments = ( state, doctorUpdatedSlot , isTodayUpdate = false) =>{
    const {appointments} = state


    const index = 0

    if (index >= 0) {
        const newSlots = getSlotsWithUpdate(appointments[index].slots ,  doctorUpdatedSlot.slot)
        const newDoctorState = {
            ...appointments[index],
            slots: newSlots,
        }
        const numberOfDoctors = appointments.length
        if (index == 0) {
            const newAppoiments = [newDoctorState, ...appointments.slice(1)]
            return  newAppoiments
        } else if (index == appointments.length - 1) {
            const newAppoiments = [...appointments.slice(0, numberOfDoctors - 1), newDoctorState]
            return newAppoiments
        } else {
            const newAppoiments = [...appointments.slice(0, index), newDoctorState, ...appointments.slice(index + 1)]
            return newAppoiments
        }
    }

    return appointments;
}