import { takeLatest } from 'redux-saga/effects'
import * as types from '../redux/types'
import {
	getDoctorMetadataSaga,
	submitOwnerDoctorToApiSaga,
	sendEmailInvitationSaga,
	assignDoctorToDispensarySaga,
	uploadImages,
	createAwsDoctorSaga,
	awsConfirmUserSaga,
	submitDoctorToApiSaga,
	doctorSignInSaga,
	doctorInfoUpdateSaga
} from './Sagas'

export const doctorSagas = [
	takeLatest(types.GET_DOCTOR_METADATA, getDoctorMetadataSaga),
	takeLatest(types.SUBMIT_OWNER_DOCTOR_TO_API, submitOwnerDoctorToApiSaga),
	takeLatest(types.ASSIGN_DOCTOR_TO_DISPENSARY_REQUEST, assignDoctorToDispensarySaga),
	takeLatest(types.SEND_EMAIL_INVITATION_REQUEST, sendEmailInvitationSaga),
	takeLatest(types.UPLOAD_IMAGES_TO_S3_REQUEST, uploadImages),
	takeLatest(types.CREATE_AWS_DOCTOR_REQUEST, createAwsDoctorSaga),
	takeLatest(types.AWS_DOCTOR_CONFIRM_OTP_REQUEST, awsConfirmUserSaga),
	takeLatest(types.SUBMIT_DOCTOR_TO_API, submitDoctorToApiSaga),
	takeLatest(types.DOCTOR_SIGN_IN, doctorSignInSaga),
	takeLatest(types.UPDATE_DOCTOR_INFO_API_REQUEST, doctorInfoUpdateSaga)
]
