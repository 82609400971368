import * as types from './types'

const defaultState = {
	loading: false,
	sendSmsloading: false,
	error: {
		show: false,
		data: {}
	},
	dialog: {
		show: false,
		content: 'T&C'
	}
}

const appReducer = (state = defaultState, action) => {
	switch (action.type) {
		case types.SHOW_LOADER:
			return {
				...state,
				loading: true
			}
		case types.HIDE_LOADER:
			return {
				...state,
				loading: false
			}
		case types.SHOW_SEND_SMS_LOADER:
			return {
				...state,
				sendSmsloading: true
			}
		case types.HIDE_SEND_SMS_LOADER:
			return {
				...state,
				sendSmsloading: false
			}
		case types.SHOW_ERROR_LOADER:
			return {
				...state,
				error: action.payload
			}
		case types.HIDE_ERROR_LOADER:
			return {
				...state,
				error: defaultState.error
			}
		case types.SHOW_MODAL:
			return {
				...state,
				dialog: action.payload
			}
		case types.HIDE_MODAL:
			return {
				...state,
				dialog: action.payload
			}
		case types.HIDE_FULL_LOADER:
			return {
				...state,
				showFullLoader: false
			}
		default:
			return state
	}
}

export default appReducer
