import React, { useState, useEffect, Fragment } from 'react'
import { Box, Container, FormGroup } from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	}
}))

export default function TextArea(props) {
	const { name, label, value, type, onChange } = props
	const classes = useStyles()

	return <Fragment></Fragment>
}
