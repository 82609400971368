import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Box, Container } from '@material-ui/core'

import * as loginActions from './redux/actionsResetPassword'

import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { RESET_PASSWORD } from 'constants/FormConstants'
import FormWizardLayout from 'containers/forms/components/FormWizardLayout'
import FormOTPWizardLayout from 'containers/forms/components/shared/FormOTPWizardLayout'
import DispensaryStaffForm from 'containers/forms/Dispensary/DispensaryStaffForm'
import AuthForm from 'containers/forms/auths/components/AuthForm'
import SuccessWizardLayout from 'containers/forms/components/shared/SuccessWizardLayout'
import { SET_EMAIL, SET_PASSWORD } from './AuthEvents'

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	formBox: {
		//border: '1px dotted blue',
		margin: theme.spacing(1),
		minHeight: 'calc(100vh - 15rem)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start'
	},
	updatePasswordFormBox: {
		width: '80%',
		[theme.breakpoints.only('xs')]: {
			width: '90%'
		}
	}
})

class PasswordUpdatedLayout extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		const { loginActions } = this.props
		// loginActions.resetResponse()
		loginActions.updateResetFormStep(1)
	}

	componentDidUpdate(prevProps) {
		const { loginState, loginActions } = this.props
		const { step } = loginState

		if (prevProps.loginState.otpRequestResponse.uuid !== loginState.otpRequestResponse.uuid) {
			this.nextAuthEvent()
		}
	}

	handleInputChange = e => {
		const { loginActions } = this.props
		const { name, value } = e.target
		loginActions.onChangeField(name, value)
	}

	nextAuthEvent = () => {
		const { loginState, loginActions } = this.props
		const { step } = loginState
		const nextPageCount = step + 1
		loginActions.updateResetFormStep(nextPageCount)
	}

	prevAuthEvent = () => {
		const { loginState, loginActions } = this.props
		const { step } = loginState
		const prevPageCount = step - 1
		loginActions.updateResetFormStep(prevPageCount !== 0 ? prevPageCount : 1)
	}

	exitForm = () => {
		const { history } = this.props
		history.push(NAVBAR_ROUTES.OWNER_LOGIN)
	}

	requestOTPAws = () => {
		const { loginState } = this.props
		const { formInputs } = loginState
		this.props.loginActions.requestOtptoReset(formInputs.email)
	}

	submitOtpValidation = () => {
		this.props.loginActions.submitResetPassword()
	}

	render() {
		const { classes, theme, history, loginState } = this.props
		const { step, formInputs } = loginState
		{
			/* SET_LOGIN | SET_EMAIL_AND_PASSWORD | SET_EMAIL */
		}
		// TODO nextAuthEvent / nextStep handle ???
		switch (step) {
			case 1:
				return (
					<FormWizardLayout
						showHeading={true}
						mainHeading='Forgotten Password'
						subHeading={null}
						stepper={null}
						prevArrow={true}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						exitForm={this.exitForm}>
						<Box component='div' className={classes.formBox}>
							<div className={classes.updatePasswordFormBox}>
								<AuthForm
									formEvent={SET_EMAIL}
									eventHeading='Enter your email address below and get a code to reset your password.'
									buttonLabel='Next'
									buttonEventType='button'
									data={formInputs}
									handleSubmit={() => {}}
									handleInputChange={this.handleInputChange}
									nextAuthEvent={this.nextAuthEvent}
									requestOTPAws={this.requestOTPAws}
								/>
							</div>
						</Box>
					</FormWizardLayout>
				)
			case 2:
				return <FormOTPWizardLayout formType={RESET_PASSWORD} />
			case 3:
				return (
					<FormWizardLayout
						showHeading={true}
						mainHeading='Change Password'
						subHeading={null}
						stepper={null}
						prevArrow={true}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						exitForm={this.exitForm}>
						<Box component='div' className={classes.formBox}>
							<div className={classes.updatePasswordFormBox}>
								<AuthForm
									formEvent={SET_PASSWORD}
									eventHeading='Enter your new password.'
									buttonLabel='Save Changes'
									buttonEventType='submit'
									data={formInputs}
									handleSubmit={() => {}}
									handleInputChange={this.handleInputChange}
									nextAuthEvent={this.nextAuthEvent}
									submitOtpValidation={this.submitOtpValidation}
								/>
							</div>
						</Box>
					</FormWizardLayout>
				)
			case 4:
				return (
					<SuccessWizardLayout
						history={this.props.history}
						formType={RESET_PASSWORD}
						isLogo={false}
						username={null}
					/>
				)
			default:
				;<Box />
		}
	}
}

function mapStateToProps(state) {
	return {
		loginState: state.resetPasswordReducer
	}
}

function mapDispatchToProps(dispatch) {
	return {
		loginActions: bindActionCreators(loginActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PasswordUpdatedLayout))
