import { put, call, select } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import * as actions from '../redux/actions'
import * as forgotPasswordactions from '../redux/actionsResetPassword'
import * as appActions from '../../../../store/AppStore/appActions'
import { AWSStatusCode } from 'config/AWSStatusCode'
import { ErrorConstants } from 'constants/ErrorConstants'
import { v4 as uuidv4 } from 'uuid'

import { reset_password_email, password, otp } from '../redux/selector'

export function* resetPasswordSaga(action) {
	yield put(appActions.showLoading())
	const username = action.payload

	let response = {}
	try {
		response = yield Auth.forgotPassword(username)
		if (response) {
			const payload = {
				uuid: uuidv4(),
				data: response
			}
			yield put(forgotPasswordactions.otptoResetResponse(payload))
			yield put(appActions.hideLoading())

			/**
			 * ! navigate to otp screen
			 * ? then validate otp
			 */
		}
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(
			appActions.showError({
				show: true,
				data: {
					header: 'Failed...',
					body: error.message,
					action: 'DISMISS',
					button_text: 'OK'
				}
			})
		)
	}
}

export function* checkUserStatus(action) {
	yield put(appActions.showLoading())
	const username = yield select(reset_password_email)
	// Auth.currentCredentials
}

export function* submitForgotPasswordSaga(action) {
	yield put(appActions.showLoading())
	const username = yield select(reset_password_email)
	const code = yield select(otp)
	const pwd = yield select(password)
	try {
		yield Auth.forgotPasswordSubmit(username, code, pwd)
		yield put(forgotPasswordactions.resetForm())
		yield put(appActions.hideLoading())
		yield put(
			appActions.showError({
				show: true,
				data: ErrorConstants.FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS
			})
		)
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(
			appActions.showError({
				show: true,
				data: ErrorConstants.FORGOT_PASSWORD.FORGOT_PASSWORD_FAILED
			})
		)
	}
}
