import React, { useState, useEffect, Fragment } from 'react'
import { Box, Button as MuiButton, Container, InputAdornment, TextField, IconButton } from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	root: {},

	buttonWrapper: {
		fontFamily: 'Roboto Condensed ,sans-serif'
	}
}))

export default function ButtonBox(props) {
	const {
		size,
		label,
		type,
		disabled = false,
		variantType = 'contained',
		themeColor = 'primary',
		onClick
	} = props

	const classes = useStyles()

	return (
		<Box className={classes.buttonWrapper}>
			<MuiButton
				type={type}
				variant={variantType}
				color={themeColor}
				size={size}
				disabled={disabled}
				onClick={onClick}>
				{label}
			</MuiButton>
		</Box>
	)
}
