import * as types from './types'

const defaultState = {
	step: 1,
	loggedIn: false,
	currentUserInfo: {},
	tempUserInfo: {},
	results: {},
	formInputs: {
		email: '',
		password: ''
	},
	dispensaryOwnerSignInResponse: null,
	tempDispensary: '',
	tempDoctor: null
}

const authReducer = (state = defaultState, action) => {
	switch (action.type) {
		case types.UPDATE_CURRENT_FORM_STEP:
			return {
				...state,
				step: action.payload
			}
		case types.UPDATE_CURRENT_USER_INFO:
			return {
				...state,
				currentUserInfo: action.payload
			}
		case types.SET_TEMP_USER_INFO:
			return {
				...state,
				tempUserInfo: action.payload
			}
		case types.SET_LOGGED_IN:
			return {
				...state,
				loggedIn: action.payload
			}
		case types.SET_LOGIN_FORM_FIELD_VALUE:
			return {
				...state,
				formInputs: {
					...state.formInputs,
					[action.payload.fieldname]: action.payload.value
				}
			}
		case types.RESET_LOGIN_FORM_VALUES:
			return {
				...state,
				formInputs: defaultState.formInputs
			}
		case types.DISPENSARY_OWNER_SIGN_IN_RESPONSE:
			return {
				...state,
				dispensaryOwnerSignInResponse: action.payload
			}
		case types.CLEAR_USER_DATA:
			return defaultState
		case types.SET_TEMP_DISPENSARY:
			return {
				...state,
				tempDispensary: action.payload
			}
		case types.CLEAR_TEMP_DISPENSARY:
			return {
				...state,
				tempDispensary: ''
			}
		case types.SET_TEMP_DOCTOR_PAYLOAD:
			return {
				...state,
				tempDoctor: action.payload
			}
		case types.CLEAR_TEMP_DATA:
			return {
				...state,
				tempDoctor: null,
				tempDispensary: '',
				tempUserInfo: {}
			}
		default:
			return state
	}
}

export default authReducer
