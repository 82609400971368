import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Box, Grid, Link } from '@material-ui/core'

import FormControlsLayout from 'containers/forms/components/FormControlsLayout'
import Controls from 'containers/forms/components/controls'
import Widgets from 'components/widgets'
import Validators from 'utils/Validators'

import FormControlEnd from 'containers/forms/components/FormControlEnd'
import { AWSStatusCode } from 'config/AWSStatusCode'

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	checkboxRoleBox: {
		padding: `${theme.spacing(0)}rem ${theme.spacing(0)}rem`,
		[theme.breakpoints.down('sm')]: {
			padding: `${theme.spacing(0)}rem ${theme.spacing(0)}rem`,
			margin: `${theme.spacing(0.1)}rem ${theme.spacing(0)}rem`
		}
	},
	forgotPassword: {
		fontSize: 20,
		paddingBottom: 50
	},
	secondaryHeading: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		fontSize: 'clamp(16px, calc(1vw + 0.8rem), 24px)',
		fontFamily: 'Roboto Bold ,sans-serif',
		color: '#0e2c39',
		paddingBottom: `${theme.spacing(5)}px`,
		[theme.breakpoints.down('xs')]: {
			paddingLeft: `${theme.spacing(4)}px`,
			marginTop: '30px'
		}
	}
})

class DispensaryStaffForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			step: 1,
			confirmationCode: '',
			newPassword: '',
			errors: {},
			passwordResetErrors: {}
		}
	}

	componentDidMount() {
		this.setState({ step: 1 }, () => console.log(this.state))
	}

	componentDidUpdate(prevProps) {
		const { staffForgotPasswordResponse, resetStaffPasswordResponse } = this.props

		if (prevProps.staffForgotPasswordResponse.uuid !== staffForgotPasswordResponse.uuid) {
			if (staffForgotPasswordResponse.status === AWSStatusCode.SUCCESS) {
				this.setState({ step: 2 }, () => console.log('reset screen'))
			}
		}
		if (prevProps.resetStaffPasswordResponse.uuid !== resetStaffPasswordResponse.uuid) {
			if (resetStaffPasswordResponse.status === AWSStatusCode.SUCCESS) {
				this.setState({ step: 1 }, () => console.log('reset screen'))
			}
		}
	}

	validator = () => {
		const { data, edit } = this.props
		const { errors } = this.state
		const { firstName, lastName, username, password } = data

		if (edit) {
			errors.firstName = Validators.required(firstName) || Validators.minLength(firstName, 3)
			errors.lastName = Validators.required(lastName) || Validators.minLength(lastName, 3)
			this.setState(prevState => ({
				errors: errors
			}))
		} else {
			errors.firstName = Validators.required(firstName) || Validators.minLength(firstName, 3)
			errors.lastName = Validators.required(lastName) || Validators.minLength(lastName, 3)
			errors.username = Validators.required(username) || Validators.validateEmail(username)
			errors.password = Validators.minLength(password, 6)
			this.setState(prevState => ({
				errors: errors
			}))
		}

		return Object.values(errors).every(err => err === '' || err === undefined)
	}

	passwordResetValidator = () => {
		const { passwordResetErrors, confirmationCode, newPassword } = this.state

		passwordResetErrors.confirmationCode =
			Validators.required(confirmationCode) || Validators.minLength(confirmationCode, 6)
		passwordResetErrors.newPassword =
			Validators.required(newPassword) || Validators.minLength(newPassword, 6)
		this.setState(prevState => ({
			passwordResetErrors: passwordResetErrors
		}))
		return Object.values(passwordResetErrors).every(err => err === '' || err === undefined)
	}

	handleSubmit = e => {
		const { onSubmit, edit } = this.props
		e.preventDefault()
		if (this.validator()) {
			onSubmit(edit)
		}
	}

	updatePassword = e => {
		const { updatePassword } = this.props
		e.preventDefault()
		updatePassword()
	}

	handlePasswordResetFieldChange = e => {
		this.setState(
			{
				[e.target.name]: e.target.value
			},
			() => console.log(this.state)
		)
	}
	changePassword = () => {
		const { data, submitPasswordReset } = this.props
		const { confirmationCode, newPassword } = this.state
		if (this.passwordResetValidator()) {
			// onSubmit(edit)
			const payload = {
				username: data.username,
				code: confirmationCode,
				newPassword: newPassword
			}
			submitPasswordReset(payload)
		}
	}

	render() {
		const { classes, data, handleInputChange, handleRoleCondition, suffix, edit } = this.props
		const { step, errors, confirmationCode, newPassword, passwordResetErrors } = this.state

		switch (step) {
			case 1:
				return (
					<FormControlsLayout>
						<Grid container>
							<Grid container spacing={0} justifyContent='space-between'>
								<Grid item xs={12} sm={5} md={5} lg={5}>
									<Controls.InputText
										label='First Name'
										type='text'
										name='firstName'
										value={data.firstName}
										onChange={handleInputChange}
										error={errors.firstName}
									/>
								</Grid>
								<Grid item xs={12} sm={5} md={5} lg={5}>
									<Controls.InputText
										label='Last Name'
										type='text'
										name='lastName'
										value={data.lastName}
										onChange={handleInputChange}
										error={errors.lastName}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Controls.InputText
									label='Email'
									type='text'
									name='username'
									value={data.username}
									disabled={edit}
									onChange={handleInputChange}
									error={errors.username}
									// endAdornmentText={suffix}
								/>
							</Grid>
							{edit ? null : (
								<Controls.InputPasswordText
									label='Password'
									type='password'
									name='password'
									disabled={edit}
									value={data.password}
									onChange={handleInputChange}
									error={errors.password}
								/>
							)}
							<Grid item xs={12}></Grid>
							<Grid item xs={12}>
								<Box
									className={classes.checkboxRoleBox}
									component='div'
									display='flex'
									flexDirection='column'
									justifyContent='center'
									alignItems='flex-start'>
									{/* <Controls.CheckBox
										label="Add as an Admin User - Full Access Rights."
										name="isAdmin"
										value={data.isAdmin}
										onChange={handleRoleCondition}/> */}
									<Controls.CheckBoxNative
										label='Add as an Admin User - Full Access Rights.'
										name='isAdmin'
										value={data.isAdmin}
										onChange={handleRoleCondition}
									/>
								</Box>
							</Grid>

							<Grid item xs={12}>
								{edit ? (
									// <Box
									// 	className={classes.checkboxRoleBox}
									// 	component='div'
									// 	display='flex'
									// 	flexDirection='column'
									// 	justifyContent='center'
									// 	alignItems='flex-start'>
									<Link
										className={classes.forgotPassword}
										underline='none'
										component='button'
										variant='body2'
										onClick={this.updatePassword}>
										Reset Password
									</Link>
								) : // </Box>
								null}
							</Grid>
							{/* checkbox end */}
							<Grid item xs={12}>
								<FormControlEnd
									btnType='button'
									label={edit ? 'Update' : 'Submit'}
									isCheckbox={false}
									disabled={false}
									onPressed={this.handleSubmit}
								/>
							</Grid>
						</Grid>
					</FormControlsLayout>
				)
			case 2: //password reset
				return (
					<FormControlsLayout>
						<Grid container>
							<Grid item xs={12}>
								<Box component='div' className={classes.secondaryHeading}>
									Enter your confirmation code sent to {data.username} and the new password
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Controls.InputText
									label='Confirmation Code'
									type='number'
									name='confirmationCode'
									value={confirmationCode}
									onChange={this.handlePasswordResetFieldChange}
									error={passwordResetErrors.confirmationCode}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controls.InputPasswordText
									label='New Password'
									type='text'
									name='newPassword'
									value={newPassword}
									onChange={this.handlePasswordResetFieldChange}
									error={passwordResetErrors.newPassword}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControlEnd
									btnType='button'
									label='Submit'
									isCheckbox={false}
									disabled={false}
									onPressed={this.changePassword}
								/>
							</Grid>
						</Grid>
					</FormControlsLayout>
				)
			default:
				return <Box>Error</Box>
		}
	}
}

function mapStateToProps(state) {
	return {
		dispensaryStaff: state.staffReducer,
		staffForgotPasswordResponse: state.staffReducer.staffForgotPasswordResponse,
		resetStaffPasswordResponse: state.staffReducer.resetStaffPasswordResponse
	}
}

function mapDispatchToProps(dispatch) {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DispensaryStaffForm))
