import * as types from './types'

export function awsCreateDispensaryOwnerAccountRequest(payload) {
	return {
		type: types.AWS_CREATE_DISPENSARY_OWNER_ACCOUNT_REQUEST,
		payload
	}
}

export function awsCreateDispensaryOwnerAccountResponse(payload) {
	return {
		type: types.AWS_CREATE_DISPENSARY_OWNER_ACCOUNT_RESPONSE,
		payload
	}
}

export function awsConfirmUserRequest(payload) {
	return {
		type: types.AWS_CONFIRM_OTP_REQUEST,
		payload
	}
}

export function awsConfirmUserResponse(payload) {
	return {
		type: types.AWS_CONFIRM_OTP_RESPONSE,
		payload
	}
}

export function getLoggedInDispensaryOwnerInfo() {
	return {
		type: types.AWS_GET_LOGGED_IN_DISPENSARY_OWNER_INFO
	}
}

export function updateUserInfo(payload) {
	return {
		type: types.UPDATE_DISPENSARY_OWNER_INFO,
		payload
	}
}

export function onChangeField(fieldname, value) {
	return {
		type: types.SET_FORM_FIELD_VALUE,
		payload: { fieldname, value }
	}
}

export function onChangeFieldHint(fieldname, value) {
	return {
		type: types.SET_FORM_FIELD_HINT,
		payload: { fieldname, value }
	}
}

export function updateFormStep(step) {
	return {
		type: types.UPDATE_CURRENT_FORM_STEP,
		payload: step
	}
}

export function updateTermAndCondition(status) {
	return {
		type: types.UPDATE_CONDITION_AND_TERMS,
		payload: status
	}
}

export function resetForm() {
	return {
		type: types.RESET_DISPENSARY_FORM_VALUES,
		payload: null
	}
}

export function setLocationAndAddress(location, address) {
	return {
		type: types.SET_LOCATION_AND_ADDRESS,
		payload: {
			location: location,
			address: address
		}
	}
}

export function flowlessRegistration(payload) {
	return {
		type: types.FLOWLESS_REGISTRATION,
		payload
	}
}

export function createDispensaryRequest(payload) {
	return {
		type: types.CREATE_DISPENSARY_REQUEST,
		payload
	}
}

export function createDispensaryResponse(payload) {
	return {
		type: types.CREATE_DISPENSARY_RESPONSE,
		payload
	}
}

export function resendOtp(payload) {
	return {
		type: types.RESEND_OTP_REQUEST,
		payload
	}
}

export function resendOtpResponse(payload) {
	return {
		type: types.RESEND_OTP_RESPONSE,
		payload
	}
}

//edit dispensary details

export function setDispensaryDetailsToForm(payload) {
	return {
		type: types.SET_DISPENSARY_DETAILS_TO_FORM,
		payload
	}
}

export function updateDispensaryInfo(payload) {
	return {
		type: types.UPDATE_DISPENSARY_INFO_REQUEST,
		payload
	}
}

export function setTempDispensary(payload) {
	return {
		type: types.SET_TEMP_DISPENSARY,
		payload
	}
}

export function clearTempDispensary() {
	return {
		type: types.CLEAR_TEMP_DISPENSARY
	}
}
