export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const SHOW_SEND_SMS_LOADER = 'SHOW_SEND_SMS_LOADER'
export const HIDE_SEND_SMS_LOADER = 'HIDE_SEND_SMS_LOADER'

export const SHOW_ERROR_LOADER = 'SHOW_ERROR_LOADER'
export const HIDE_ERROR_LOADER = 'HIDE_ERROR_LOADER'
export const RESET_ERROR_STATE = 'RESET_ERROR_STATE'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export const HIDE_FULL_LOADER = 'HIDE_FULL_LOADER';
