import { put, call, fork, race, take, cancel, select } from 'redux-saga/effects'
import {
	assignDoctorToDispensary,
	getDoctorMetaData,
	sendEmailInvitation,
	submitDispensaryOwnerDoctor,
	updateDoctorInfo
} from '../api'
// import { Auth } from 'aws-amplify';
import * as actions from '../redux/actions'
import * as appActions from '../../../../store/AppStore/appActions'
import * as authActions from '../../auths/redux/actions'
import { v4 as uuidv4 } from 'uuid'
import { Auth, Storage } from 'aws-amplify'
import { AWSStatusCode } from 'config/AWSStatusCode'
import { tempUserInfo } from '../redux/selector'
import { ErrorConstants } from 'constants/ErrorConstants'
// import { AWSStatusCode } from 'config/AWSStatusCode';

export function* getDoctorMetadataSaga() {
	yield put(appActions.showLoading())
	try {
		const response = yield call(getDoctorMetaData)
		if (response.status === 200) {
			if (response.data.length !== 0) {
				yield put(actions.setDoctorMetadata(response.data))
			}
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
	}
}

export function* submitOwnerDoctorToApiSaga(action) {
	yield put(appActions.showLoading())
	const body = action.payload
	const user = yield Auth.currentAuthenticatedUser()
	const token = user.signInUserSession.idToken.jwtToken
	const options = {
		headers: {
			Authorization: token
		}
	}
	try {
		const response = yield call(submitDispensaryOwnerDoctor, body, options)
		if (response.status === 201) {
			const data = {
				uuid: uuidv4(),
				data: response.data
			}
			yield put(actions.submitOwnerDoctorToApiResponse(data))
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(appActions.showError({ show: true, data: ErrorConstants.DOCTOR.CREATION_FAILED }))
	}
}

export function* assignDoctorToDispensarySaga(action) {
	yield put(appActions.showLoading())
	const body = action.payload
	const user = yield Auth.currentAuthenticatedUser()
	const token = user.signInUserSession.idToken.jwtToken
	const options = {
		headers: {
			Authorization: token
		}
	}
	try {
		const response = yield call(assignDoctorToDispensary, body, options)
		if (response.status === 201) {
			const data = {
				uuid: uuidv4(),
				data: response.data
			}
			yield put(actions.assignDoctorToDispensaryResponse(data))
			yield put(actions.resetForm())
		}

		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(appActions.showError({ show: true, data: ErrorConstants.DOCTOR.ASSIGN_FAILED }))
	}
}

export function* sendEmailInvitationSaga(action) {
	yield put(appActions.showLoading())
	const body = action.payload

	const user = yield Auth.currentAuthenticatedUser()
	const token = user.signInUserSession.idToken.jwtToken
	const options = {
		headers: {
			Authorization: token
		}
	}
	try {
		const response = yield call(sendEmailInvitation, body, options)
		if (response.status === 200) {
			const payload = {
				uuid: uuidv4(),
				data: response
			}
			yield put(actions.setEmailInvitationResponse(payload))
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(
			appActions.showError({ show: true, data: ErrorConstants.DOCTOR.SEND_EMAIL_INVITATION_FAILED })
		)
	}
}

export function* uploadImages(action) {
	yield put(appActions.showLoading())
	var images = []
	var response = {}
	for (var i in action.payload) images.push([action.payload[i]])

	try {
		const res = yield Storage.put(splitName(images[0][0]), images[0][0], {
			contentType: images[0][0].type
		})
		if (res.hasOwnProperty('key')) {
			const image1 = {
				key: res.key,
				status: 'SUCCESS'
			}
			response['image1'] = image1
			const res2 = yield Storage.put(splitName(images[1][0]), images[1][0], {
				contentType: images[1][0].type
			})
			if (res2.hasOwnProperty('key')) {
				const image2 = {
					key: res.key,
					status: 'SUCCESS'
				}
				response['image2'] = image2
				response['status'] = 'SUCCESS'
				yield put(actions.uploadImagesToS3Response(response))
				yield put(appActions.hideLoading())
			}
		}
	} catch (error) {
		const response = {
			status: 'FAILED'
		}
		yield put(actions.uploadImagesToS3Response(response))
		yield put(appActions.hideLoading())
		yield put(appActions.showError({ show: true, data: ErrorConstants.IMAGE_UPLOAD.FAILED }))
	}
}

function splitName(data) {
	const str = data.name
	var [filename, extension] = str
		.split('.')
		.reduce(
			(acc, val, i, arr) =>
				i == arr.length - 1 ? [acc[0].substring(1), val] : [[acc[0], val].join('.')],
			[]
		)
	return filename
}

export function* createAwsDoctorSaga(action) {
	yield put(appActions.showLoading())
	const body = action.payload
	const payload = body.payload
	try {
		yield Auth.signOut()
		yield Auth.signUp(payload)
		const response = {
			uuid: uuidv4(),
			status: AWSStatusCode.SUCCESS,
			from: body.from
		}
		yield put(authActions.setTempUserInfo(payload))
		yield put(actions.awsDoctorRegistrationResponse(response))
		// TODO HIDE LOADER
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		if (error.code === 'UsernameExistsException') {
			yield put(
				appActions.showError({ show: true, data: ErrorConstants.AUTH.EMAIL_ALREADY_REGISTERED })
			)
			// TODO SHOW POPUP
		}
		if (error.code === 'InvalidPasswordException') {
			yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.PASSWORD_NOT_STRONG }))
			// TODO SHOW POPUP
		}
	}
}

export function* awsConfirmUserSaga(action) {
	yield put(appActions.showLoading())
	const body = action.payload
	const userInfo = yield select(tempUserInfo)
	try {
		yield Auth.confirmSignUp(userInfo.username, body.code)
		const response = {
			uuid: uuidv4(),
			status: AWSStatusCode.SUCCESS,
			form: body.formType
		}

		yield put(actions.awsConfirmUserResponse(response))

		const signInData = {
			username: userInfo.username,
			password: userInfo.password,
			fromDoctorSignIn: true
		}

		yield put(actions.doctorSignIn(signInData))

		yield put(appActions.hideLoading())
	} catch (error) {
		if (error) {
			yield put(appActions.hideLoading())
			yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.INVALID_OTP }))
			// TODO SHOW POPUP
		}
	}
}

export function* submitDoctorToApiSaga(action) {
	yield put(appActions.showLoading())
	const body = action.payload

	const user = yield Auth.currentAuthenticatedUser()
	const token = user.signInUserSession.idToken.jwtToken
	const options = {
		headers: {
			Authorization: token
		}
	}
	try {
		const response = yield call(submitDispensaryOwnerDoctor, body, options)
		if (response.status === 201) {
			const data = {
				uuid: uuidv4(),
				data: response.data
			}
			yield put(actions.submitDoctorToApiResponse(data))
			yield put(actions.resetForm())
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(appActions.showError({ show: true, data: ErrorConstants.DOCTOR.ASSIGN_FAILED }))
	}
}

export function* doctorSignInSaga(action) {
	let response = {}
	const body = action.payload
	yield put(appActions.showLoading())
	try {
		// TODO SHOW Loader
		response = yield Auth.signIn(body.username, body.password)
		if (response) {
			yield put(authActions.getCurrentUserInfo())
			const signInResponse = {
				status: AWSStatusCode.SUCCESS,
				uuid: uuidv4(),
				assign: true
			}
			yield put(actions.doctorSignInResponse(signInResponse))
		}
		// if (body.fromDoctorSignIn) {
		// 	const signInResponse = {
		// 		status: AWSStatusCode.SUCCESS,
		// 		uuid: uuidv4()
		// 	}
		// 	yield put(actions.doctorSignInResponse(signInResponse))
		// }
		// TODO HIDE LOADER
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.INVALID_CREDENTIALS }))
	}
}

export function* doctorInfoUpdateSaga(action) {
	yield put(appActions.showLoading())
	const body = action.payload
	const user = yield Auth.currentAuthenticatedUser()
	const token = user.signInUserSession.idToken.jwtToken
	const options = {
		headers: {
			Authorization: token
		}
	}
	try {
		const response = yield call(updateDoctorInfo, body, options)
		// if (response.status === 201) {
		//     const data = {
		//         uuid: uuidv4(),
		//         data: response.data
		//     }
		yield put(appActions.hideLoading())
		yield put(actions.resetForm())
		yield put(
			appActions.showError({ show: true, data: ErrorConstants.PROFILE.DOCTOR_PROFILE_UPDATE_SUCCESS })
		)
		// }
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(
			appActions.showError({ show: true, data: ErrorConstants.PROFILE.DOCTOR_PROFILE_UPDATE_FAILED })
		)
	}
}
