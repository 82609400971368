import { put, call, select } from 'redux-saga/effects'
import Amplify, { Auth } from 'aws-amplify'
import * as actions from '../redux/dispensaryStaffActions'
import * as authActions from '../../auths/redux/actions'
import * as appActions from '../../../../store/AppStore/appActions'
import { AwsConstants } from 'constants/AwsConstants'
import { AWSStatusCode } from 'config/AWSStatusCode'
import { v4 as uuidv4 } from 'uuid'
import { assignStaffToDispensary } from '../api'
import { ErrorConstants } from 'constants/ErrorConstants'

export function* createStaffMemberAwsSaga(action) {
	let response = {}
	yield put(appActions.showLoading())
	// yield Amplify.Auth.configure({
	// 	Auth: {
	// 		region: process.env.REACT_APP_STAFF_REGION,
	// 		userPoolId: process.env.REACT_APP_STAFF_USER_POOL_ID,
	// 		userPoolWebClientId: process.env.REACT_APP_STAFF_USER_POOL_WEB_CLIENT_ID,
	// 		identityPoolId: process.env.REACT_APP_STAFF_IDENTITY_POOL_ID
	// 	}
	// })

	try {
		response = yield Auth.signUp(action.payload)
		if (response) {
			const payload = {
				uuid: uuidv4(),
				status: AWSStatusCode.SUCCESS
			}
			yield put(actions.createStaffMemberAwsResponse(payload))
		}
		yield put(appActions.hideLoading())
	} catch (err) {
		yield put(appActions.hideLoading())
		if (err.code === 'InvalidPasswordException') {
			yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.PASSWORD_NOT_STRONG }))
			// dispatch(appActions.showErrorModal(ERRORS.AUTH.PASSWORD_NOT_STRONG));
		} else if ((err.code = 'UsernameExistsException')) {
			yield put(appActions.showError({ show: true, data: ErrorConstants.STAFF.ALREADY_REGISTERED }))
		}
	}
}

export function* confirmStaffUserAccountSaga(action) {
	let response = {}
	yield put(appActions.showLoading())
	try {
		response = yield Auth.confirmSignUp(action.payload.username, action.payload.code)
		if (response) {
			const payload = {
				uuid: uuidv4(),
				status: AWSStatusCode.SUCCESS
			}
			yield put(actions.confirmStaffUserAwsResponse(payload))
		}
		yield put(appActions.hideLoading())
	} catch (err) {
		yield put(appActions.hideLoading())
		if (err.code === 'InvalidPasswordException') {
			yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.INVALID_OTP }))
			// dispatch(appActions.showErrorModal(ERRORS.AUTH.PASSWORD_NOT_STRONG));
		}
	}
}

export function* assignStaffToDispensarySaga(action) {
	const body = action.payload
	let response = {}
	yield put(appActions.showLoading())
	const user = yield Auth.currentAuthenticatedUser()
	const token = user.signInUserSession.idToken.jwtToken
	const options = {
		headers: {
			Authorization: token
		}
	}
	try {
		response = yield call(assignStaffToDispensary, body, options)
		if (response.status === 201) {
			let data = {
				uuid: uuidv4(),
				show: true,
				message: 'Staff Created',
				status: 201
			}
			yield put(actions.resetForm())
			yield put(actions.submitDispensaryStaffFormResponse(data))
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(appActions.showError({ show: true, data: ErrorConstants.STAFF.FAILED }))
	}
}

export function* updateStaffMemberSaga(action) {
	const body = action.payload
	let response = {}
	yield put(appActions.showLoading())
	const user = yield Auth.currentAuthenticatedUser()
	const token = user.signInUserSession.idToken.jwtToken
	const options = {
		headers: {
			Authorization: token
		}
	}
	try {
		response = yield call(assignStaffToDispensary, body, options)
		if (response.status === 201) {
			let data = {
				uuid: uuidv4(),
				show: true,
				message: 'Staff Updated',
				status: 201
			}
			yield put(actions.resetForm())
			yield put(appActions.showError({ show: true, data: ErrorConstants.STAFF.UPDATED }))
			// yield put(actions.submitDispensaryStaffFormResponse(data))
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(appActions.showError({ show: true, data: ErrorConstants.STAFF.FAILED }))
	}
}

export function* updateStaffPasswordSaga(action) {
	let response = {}
	yield put(appActions.showLoading())
	// yield Amplify.Auth.configure({
	// 	Auth: {
	// 		region: process.env.REACT_APP_STAFF_REGION,
	// 		userPoolId: process.env.REACT_APP_STAFF_USER_POOL_ID,
	// 		userPoolWebClientId: process.env.REACT_APP_STAFF_USER_POOL_WEB_CLIENT_ID,
	// 		identityPoolId: process.env.REACT_APP_STAFF_IDENTITY_POOL_ID
	// 	}
	// })

	//TODO ?
	try {
		response = yield Auth.forgotPassword(action.payload)
		if (response) {
			const payload = {
				uuid: uuidv4(),
				status: AWSStatusCode.SUCCESS,
				message: response
			}
			yield put(actions.staffForgotPasswordResponse(payload))
		}
		yield put(appActions.hideLoading())
	} catch (err) {
		yield put(appActions.hideLoading())
		if (err.code === 'NotAuthorizedException') {
			yield put(appActions.showError({ show: true, data: ErrorConstants.STAFF.RESEND_OTP_FAILED }))
			// dispatch(appActions.showErrorModal(ERRORS.AUTH.PASSWORD_NOT_STRONG));
		}
	}
}

export function* submitStaffPasswordResetSaga(action) {
	let response = {}
	const data = action.payload
	yield put(appActions.showLoading())
	// yield Amplify.Auth.configure({
	// 	Auth: {
	// 		region: process.env.REACT_APP_STAFF_REGION,
	// 		userPoolId: process.env.REACT_APP_STAFF_USER_POOL_ID,
	// 		userPoolWebClientId: process.env.REACT_APP_STAFF_USER_POOL_WEB_CLIENT_ID,
	// 		identityPoolId: process.env.REACT_APP_STAFF_IDENTITY_POOL_ID
	// 	}
	// })
	try {
		response = yield Auth.forgotPasswordSubmit(data.username, data.code, data.newPassword)

		const payload = {
			uuid: uuidv4(),
			status: AWSStatusCode.SUCCESS
		}
		yield put(actions.submitStaffPasswordResetResponse(payload))
		yield put(appActions.showError({ show: true, data: ErrorConstants.STAFF.UPDATED }))
		yield put(appActions.hideLoading())
	} catch (err) {
		yield put(appActions.hideLoading())
		const error = {
			header: 'Failed',
			body: err.message,
			action: 'DISMISS',
			button_text: 'OK'
		}
		yield put(appActions.showError({ show: true, data: error }))
	}
}
