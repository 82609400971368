import * as types from './typesResetPassword'

const defaultState = {
	step: 1,
	isloggedin: false,
	results: {},
	formInputs: {
		email: '',
		password: '',
		retypePassword: ''
	},
	otp: '',
	otpRequestResponse: {
		status: '',
		uuid: '',
		data: {}
	}
}

const resetPasswordReducer = (state = defaultState, action) => {
	switch (action.type) {
		case types.SET_LOGIN_FORM_FIELD_VALUE:
			return {
				...state,
				formInputs: {
					...state.formInputs,
					[action.payload.fieldname]: action.payload.value
				}
			}
		case types.UPDATE_CURRENT_RESET_FORM_STEP:
			return {
				...state,
				step: action.payload
			}

		case types.RESET_LOGIN_FORM_VALUES:
			return {
				...state,
				formInputs: defaultState.formInputs
			}

		case types.SET_LOGIN_STATE:
			return {
				...state,
				isloggedin: action.payload
			}
		case types.SET_OTP_REQUEST_RESPONSE:
			return {
				...state,
				otpRequestResponse: action.payload
			}
		case types.SET_OTP:
			return {
				...state,
				otp: action.payload
			}
		default:
			return state
	}
}

export default resetPasswordReducer
