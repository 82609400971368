/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onGetAppointment = /* GraphQL */ `
  subscription OnGetAppointment {
    onGetAppointment {
      doctor_id
      dispensary_id
      day
      date
      slot {
        slot_id
        status
        slot_time
      }
    }
  }
`;
export const onAppointmentUpdate = /* GraphQL */ `
  subscription OnAppointmentUpdate($dispensary_id: String!) {
    onAppointmentUpdate(dispensary_id: $dispensary_id) {
      date
      day
      dispensary_id
      doctor_id
      slot {
        patient {
          PK
          SK
          address
          age
          booking_at
          booking_method
          check_in_at
          check_out_at
          date_of_birth
          display_name
          family_member
          first_name
          gender
          id
          last_name
          middle_name
          mobile_no
          mobile_number
          pre_conditions
          profile_photo_url
          symptom
        }
        previous_visits {
          date
          doctor {
            display_name
          }
          note
          visit_number
        }
        slot_id
        slot_time
        status
        symptom
        note
      }
    }
  }
`;
