import * as types from './types'

export function onChangeField(fieldname, value) {
	return {
		type: types.SET_LOGIN_FORM_FIELD_VALUE,
		payload: { fieldname, value }
	}
}

export function resetForm() {
	return {
		type: types.RESET_LOGIN_FORM_VALUES,
		payload: null
	}
}

export function setLoginState(payload) {
	return {
		type: types.SET_LOGIN_STATE,
		payload
	}
}

export function getCurrentUserInfo() {
	return {
		type: types.GET_CURRENT_USER_INFO
	}
}

export function updateCurrentUserInfo(payload) {
	return {
		type: types.UPDATE_CURRENT_USER_INFO,
		payload
	}
}

export function setTempUserInfo(payload) {
	return {
		type: types.SET_TEMP_USER_INFO,
		payload
	}
}

export function dispensaryOwnerSignIn(payload) {
	return {
		type: types.DISPENSARY_OWNER_SIGN_IN_REQUEST,
		payload
	}
}

export function dispensaryOwnerSignInResponse(payload) {
	return {
		type: types.DISPENSARY_OWNER_SIGN_IN_RESPONSE,
		payload
	}
}

export function setLoggedIn(payload) {
	return {
		type: types.SET_LOGGED_IN,
		payload
	}
}

export function signOut() {
	return {
		type: types.SIGNOUT
	}
}

export function clearUserData() {
	return {
		type: types.CLEAR_USER_DATA
	}
}

export function setTempDispensary(payload) {
	return {
		type: types.SET_TEMP_DISPENSARY,
		payload
	}
}

export function clearTempDispensary() {
	return {
		type: types.CLEAR_TEMP_DISPENSARY
	}
}

export function setTempDoctorPayload(payload) {
	return {
		type: types.SET_TEMP_DOCTOR_PAYLOAD,
		payload
	}
}

export function clearTempData() {
	return {
		type: types.CLEAR_TEMP_DATA
	}
}

export function updateFormStep(step) {
	return {
		type: types.UPDATE_CURRENT_FORM_STEP,
		payload: step
	}
}
