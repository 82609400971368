import React, { useState, useEffect, Fragment } from 'react'
import { Box, Container, Paper } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { CheckCircleOutline, HourglassFullTwoTone, HourglassEmpty } from '@material-ui/icons'
import clsx from 'clsx'

import Widgets from 'components/widgets'

const useStyles = makeStyles(theme => ({
	root: {
		flex: 1
	},
	containerBox: {
		//border: '1px dotted red',
		padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
	},
	inlineBox: {
		//border: '1px dotted pink',
		display: 'flex',
		flexWrap: 'wrap',
		margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
		[theme.breakpoints.down('xs')]: {
			margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`
		}
	},

	leftCol: {
		flex: 1,
		boxSizing: 'border-box'
	},
	rightCol: {
		flex: 2,
		boxSizing: 'border-box'
		//display: 'flex',
		//flexDirection:'column',
	},
	rightColBoxLayout: {
		display: 'grid',
		gridTemplateColumns: 'auto auto',
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			flexDirection: 'column'
		}
	},
	linkButtonBox: {
		margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`
	},
	inlineRecordBox: {
		backgroundColor: '#fafbfc', // fafbfc
		margin: `${theme.spacing(0.8)}px ${theme.spacing(0)}px`,
		[theme.breakpoints.down('xs')]: {
			// margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
		}
	},

	boxHeading: {
		fontFamily: 'Roboto Bold ,sans-serif',
		fontSize: 'clamp(20px, 1.6vw, 35px)',
		color: '#0E2C39'
	},
	recordHeading: {
		fontFamily: 'Roboto Medium ,sans-serif',
		fontSize: 'clamp(16px, 1.3vw, 30px)',
		color: '#2699FB',
		padding: `${theme.spacing(0.2)}px ${theme.spacing(0.5)}px`,

		'&:hover': {
			textDecoration: 'none',
			cursor: 'pointer',
			color: '#2b7ec5'
		}
	},
	statusText: {
		//border: '2px dotted purple',
		//flex: 1,
		//paddingRight: theme.spacing(1),
		fontFamily: 'Roboto Medium ,sans-serif',
		fontSize: 'clamp(16px, 1.3vw, 30px)',
		color: '#0E2C39'
	},
	statusInfoItems: {
		//border: '1px dotted pink',
		width: '20rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between', // space-evenly
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			width: '100%'
		}
	},
	statusIcon: {
		//fontSize:'1rem'
		marginRight: theme.spacing(0.5)
	},

	statusDescription: {
		fontFamily: 'Roboto Medium ,sans-serif',
		fontSize: 'clamp(16px, 1.3vw, 30px)',
		color: '#0E2C39',
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			marginLeft: theme.spacing(0)
		}
	},
	statusOK: {
		color: '#39CFBA'
	},
	statusPending: {
		color: '#ffc107'
	}
}))

const DispensaryViewBox = props => {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const { boxHeading, data = [{}], btnLabel = 'unknown', isButton = true, routeTo, onPress } = props

	function test() {
		//console.log(JSON.stringify(data, null, 1))
		data.map((person, idx) => {
			console.log('')
		})
	}

	return (
		<Paper variant='outlined' square>
			<Box
				component='div'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='flext-start'
				className={classes.containerBox}>
				<div className={classes.inlineBox}>
					<Box component='div' className={clsx(classes.leftCol, classes.boxHeading)}>
						{boxHeading}
					</Box>
				</div>
				{/* ....all records start........ */}

				{data.map((dispensary, idx) => (
					<div className={clsx(classes.inlineBox, classes.inlineRecordBox)} key={idx}>
						<Box
							component='div'
							className={clsx(classes.leftCol, classes.recordHeading)}
							onClick={() => onPress(dispensary)}>
							{dispensary.name}
						</Box>
						<Box component='div' className={clsx(classes.rightCol, classes.rightColBoxLayout)}>
							<span className={classes.statusText}>
								<p className={classes.statusInfoItems}>
									Registered
									<CheckCircleOutline
										className={clsx(classes.statusIcon, classes.statusOK)}
									/>
								</p>
							</span>
							<span className={classes.statusDescription}>{dispensary.submittedText}</span>
						</Box>
					</div>
				))}

				{/* ...all records end..... */}
				<div className={clsx(classes.inlineBox, classes.linkButtonBox)}>
					{isButton && (
						<Widgets.ButtonBox
							type='button'
							disabled={false}
							label={btnLabel}
							variantType='outlined'
							color='primary'
							onClick={() => routeTo(boxHeading)}
							size='medium'
						/>
					)}
				</div>
			</Box>
		</Paper>
	)
}

export default DispensaryViewBox
