export const ErrorConstants = {
	AUTH: {
		EMAIL_ALREADY_REGISTERED: {
			header: 'Email address already Registered',
			body:
				'We found an active account registered with us using the entered email address. Please continue to Login',
			action: 'NAVIGATE_TO_LOGIN',
			button_text: 'Login'
		},
		PASSWORD_NOT_STRONG: {
			header: 'Weak Password',
			body:
				'Your password must have at least one uppercase letter, one lowercase letter, one number and a symbol',
			action: 'DISMISS',
			button_text: 'OK'
		},
		WRONG_PASSWORD: {
			header: 'Failed',
			body: 'Your password is wrong. please enter the correct password',
			action: 'DISMISS',
			button_text: 'OK'
		},
		INVALID_OTP: {
			header: 'Invalid OTP',
			body: 'Your OTP is invalid',
			action: 'DISMISS',
			button_text: 'OK'
		},
		RESENT_OTP_SUCCESS: {
			header: 'Success',
			body: 'OTP resent successfully',
			action: 'DISMISS',
			button_text: 'OK'
		},
		RESENT_OTP_FAILED: {
			header: 'Failed',
			body: 'Invalid credentials. Please login',
			action: 'DISMISS',
			button_text: 'OK'
		},
		INVALID_CREDENTIALS: {
			header: 'Failed',
			body: 'Incorrect username or password.',
			action: 'DISMISS',
			button_text: 'OK'
		},
		STAFF_LOGIN: {
			header: 'Access Denied',
			body: 'Please contact dispensary owner',
			action: 'LOGOUT',
			button_text: 'OK'
		},
		DOCTOR_LOGIN: {
			header: 'Access Denied',
			body: 'Please download ezdoc pro app',
			action: 'LOGOUT',
			button_text: 'OK'
		},
		LIMIT_EXCEED: {
			header: 'Access Denied',
			body: 'Limit exceed. Please try again after some time ',
			action: 'DISMISS',
			button_text: 'OK'
		}
	},
	CONTACT_US: {
		SUCCESS: {
			header: 'Sent Successfully',
			body: 'Thanks for contacting us',
			action: 'DISMISS',
			button_text: 'OK'
		},
		FAILED: {
			header: 'Failed',
			body: 'Unable to send ',
			action: 'DISMISS',
			button_text: 'OK'
		}
	},
	DISPENSARY_REGISTRATION: {
		SUCCESS: {
			header: 'Sent Successfully',
			body: 'Dispensary registered successfully',
			action: 'NAVIGATE_TO_PORTAL',
			button_text: 'OK'
		},
		FAILED: {
			header: 'Failed...',
			body: 'Unable to register Dispensary',
			action: 'DISMISS',
			button_text: 'OK'
		}
	},
	STAFF: {
		SUCCESS: {
			header: 'SUCCESS',
			body: 'User created successfully',
			action: 'DISMISS',
			button_text: 'OK'
		},
		ALREADY_REGISTERED: {
			header: 'FAILED',
			body: 'An account with the given email already exists.',
			action: 'DISMISS',
			button_text: 'OK'
		},
		UPDATED: {
			header: 'SUCCESS',
			body: 'Staff member updated succesfully',
			action: 'NAVIGATE_TO_PORTAL',
			button_text: 'OK'
		},
		FAILED: {
			header: 'Failed...',
			body: 'Unable to create new staff member',
			action: 'DISMISS',
			button_text: 'OK'
		},
		RESEND_OTP_FAILED: {
			header: 'Failed...',
			body: 'Unable to reset password',
			action: 'DISMISS',
			button_text: 'OK'
		}
	},
	DOCTOR: {
		CREATION_SUCCESS: {
			header: 'SUCCESS',
			body: 'User created successfully',
			action: 'DISMISS',
			button_text: 'OK'
		},
		CREATION_FAILED: {
			header: 'Failed...',
			body: 'Unable to create doctor',
			action: 'DISMISS',
			button_text: 'OK'
		},
		ASSIGN_FAILED: {
			header: 'Failed...',
			body: 'Unable to assign doctor to dispensary',
			action: 'DISMISS',
			button_text: 'OK'
		},
		AWS_USER_ALREADY_EXISTS: {
			header: 'Failed...',
			body: 'User already exists',
			action: 'DISMISS',
			button_text: 'OK'
		},
		AWS_USER_AUTHORIZED: {
			header: 'Failed...',
			body: 'Not authorized',
			action: 'DISMISS',
			button_text: 'OK'
		},
		SEND_EMAIL_INVITATION_FAILED: {
			header: 'Failed...',
			body: 'Email invitation sending failed',
			action: 'DISMISS',
			button_text: 'OK'
		},
		GET_DOCTOR_FAILED: {
			header: 'Failed...',
			body: 'Get doctor failed',
			action: 'DISMISS',
			button_text: 'OK'
		},
		GET_STAFF_FAILED: {
			header: 'Failed...',
			body: 'Get staff failed',
			action: 'DISMISS',
			button_text: 'OK'
		}
	},
	IMAGE_UPLOAD: {
		FAILED: {
			header: 'Failed...',
			body: 'Unable to upload images',
			action: 'DISMISS',
			button_text: 'OK'
		}
	},
	PROFILE: {
		DISPENSARY_UPDATE_SUCCESS: {
			header: 'Successfully Updated!',
			body: 'Dispensary details have been updated successfully!',
			action: 'NAVIGATE_TO_PORTAL',
			button_text: 'OK'
		},
		DISPENSARY_UPDATE_FAILED: {
			header: 'Update Failed',
			body: 'Unable to update dispensary details',
			action: 'DISMISS',
			button_text: 'OK'
		},
		DOCTOR_PROFILE_UPDATE_SUCCESS: {
			header: 'Successfully Updated!',
			body: 'Doctor profile details have been updated successfully!',
			action: 'NAVIGATE_TO_PORTAL',
			button_text: 'OK'
		},
		DOCTOR_PROFILE_UPDATE_FAILED: {
			header: 'Update Failed',
			body: 'Unable to update doctor profile',
			action: 'DISMISS',
			button_text: 'OK'
		}
	},
	FORGOT_PASSWORD: {
		FORGOT_PASSWORD_SUCCESS: {
			header: 'Successfully Updated!',
			body: 'Your password has been updated successfully!',
			action: 'NAVIGATE_TO_LOGIN',
			button_text: 'OK'
		},
		FORGOT_PASSWORD_FAILED: {
			header: 'Update Failed',
			body: 'Unable to update password!',
			action: 'NAVIGATE_TO_LOGIN',
			button_text: 'OK'
		}
	}
}
