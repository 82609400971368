import { put, call } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import * as actions from '../redux/actions'
import * as appActions from '../../../../store/AppStore/appActions'
import { AWSStatusCode } from 'config/AWSStatusCode'
import { ErrorConstants } from 'constants/ErrorConstants'

export function* getCurrentUserInfo() {
	yield put(appActions.showLoading())
	try {
		const currentUserInfo = yield Auth.currentUserInfo()
		if (currentUserInfo) {
			// if (currentUserInfo.attributes['custom:role'] === 'staff') {
			// 	// yield put(actions.signOut())
			// 	yield put(appActions.hideLoading())
			// 	yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.STAFF_SIGNOFF }))
			// } else {
			yield put(actions.updateCurrentUserInfo(currentUserInfo))
			yield put(actions.setLoggedIn(true))
			// }
		} else {
			yield put(actions.updateCurrentUserInfo({}))
			yield put(actions.setLoggedIn(false))
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
	}
}

export function* dispensaryOwnerSignIn(action) {
	let response = {}
	const body = action.payload
	yield put(appActions.showLoading())
	try {
		// TODO SHOW Loader
		response = yield Auth.signIn(body.username, body.password)
		if (response) {
			if (response.attributes['custom:role'] === 'staff') {
				yield put(actions.signOut())
				yield put(appActions.hideLoading())
				yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.STAFF_LOGIN }))
			} else if (response.attributes['custom:role'] === 'doctor') {
				yield put(actions.signOut())
				yield put(appActions.hideLoading())
				yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.DOCTOR_LOGIN }))
			} else {
				yield put(actions.getCurrentUserInfo())
				if (body.fromOwnerLoginPage) {
					const signInResponse = {
						status: AWSStatusCode.SUCCESS
					}
					yield put(actions.dispensaryOwnerSignInResponse(signInResponse))
				}
			}
		}
		// TODO HIDE LOADER
		yield put(appActions.hideLoading())
	} catch (error) {

		if (error.code === 'UserNotConfirmedException') {
			// TODO -> trigger otp -> Navigate to otp screen
			let response = yield Auth.resendSignUp(body.username)
			if (response) {
				let data = {
					header: 'Verify Your Account',
					body: 'Your OTP code resent to ' + response.CodeDeliveryDetails.Destination,
					action: 'NAVIGATE_TO_OTP',
					button_text: 'VERIFY ACCOUNT'
				}
				yield put(appActions.hideLoading())
				yield put(appActions.showError({ show: true, data: data }))
			}
		}
		if (error.code === 'NotAuthorizedException') {
			yield put(appActions.hideLoading())
			yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.INVALID_CREDENTIALS }))
		}

		if (error.code === 'LimitExceededException') {
			yield put(appActions.hideLoading())
			yield put(appActions.showError({ show: true, data: ErrorConstants.AUTH.LIMIT_EXCEED }))
		}
	}
}

export function* signOut() {
	yield put(appActions.showLoading())
	try {
		const currentUserInfo = yield Auth.currentUserInfo()
		if (currentUserInfo) {
			yield Auth.signOut()
			yield put(actions.setLoggedIn(false))
			yield put(actions.clearUserData())
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
	}
}

