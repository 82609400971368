import { takeLatest } from 'redux-saga/effects';
import * as types from '../redux/types';
import {
    getAppointmentsSaga,
    bookAppointmentSaga,
    requestDoctorsByDispensarySaga,
    navigateToKioskSaga,
    navigateToKioskMobileNumberSaga
} from './Sagas';

export const appointmentsSagas = [
    takeLatest(types.REQUEST_APPOINTMENTS, getAppointmentsSaga),
    takeLatest(types.REQUEST_BOOK_APPOINTMENT, bookAppointmentSaga),
    takeLatest(types.REQUEST_DOCTORS_BY_DISPENSARY,requestDoctorsByDispensarySaga),
    takeLatest(types.NAVIGATE_TO_KIOSK,navigateToKioskSaga),
    takeLatest(types.NAVIGATE_TO_KIOSK_MOBILE,navigateToKioskMobileNumberSaga),

]
