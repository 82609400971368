import { put, call, select } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import * as actions from '../redux/actions'
import * as authActions from '../../auths/redux/actions'
import * as appActions from '../../../../store/AppStore/appActions'
import { v4 as uuidv4 } from 'uuid'
import { AWSStatusCode } from 'config/AWSStatusCode'
import { tempUserInfo, currentUserInfo } from '../redux/selector'

import { registerDispensary } from '../api'
import { ErrorConstants } from 'constants/ErrorConstants'

export function* awsCreateDispensaryOwnerAccountSaga(action) {
	yield put(appActions.showLoading())
	const body = action.payload
	const payload = body.payload
	try {
		let resp = {}
		yield Auth.signOut()
		resp = yield Auth.signUp(payload)
		const response = {
			uuid: uuidv4(),
			status: AWSStatusCode.SUCCESS,
			from: body.from
		}
		yield put(authActions.setTempUserInfo(payload))
		yield put(actions.awsCreateDispensaryOwnerAccountResponse(response))
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		if (error.code === 'UsernameExistsException') {
			yield put(
				appActions.showError({
					show: true,
					data: ErrorConstants.AUTH.EMAIL_ALREADY_REGISTERED
				})
			)
		}

		if (error.code === 'InvalidPasswordException') {
			yield put(
				appActions.showError({
					show: true,
					data: ErrorConstants.AUTH.PASSWORD_NOT_STRONG
				})
			)
		}
	}
}

export function* awsConfirmUserSaga(action) {
	yield put(appActions.showLoading())
	const body = action.payload
	const userInfo = yield select(tempUserInfo)
	try {
		yield Auth.confirmSignUp(userInfo.username, body.code)

		const response = {
			uuid: uuidv4(),
			status: AWSStatusCode.SUCCESS,
			form: body.formType
		}
		yield put(actions.awsConfirmUserResponse(response))

		const signInData = {
			username: userInfo.username,
			password: userInfo.password
		}

		yield put(authActions.dispensaryOwnerSignIn(signInData))
		yield put(appActions.hideLoading())
	} catch (error) {
		if (error) {
			yield put(appActions.hideLoading())
			yield put(
				appActions.showError({
					show: true,
					data: ErrorConstants.AUTH.INVALID_OTP
				})
			)
		}
	}
}

export function* createDispensarySaga(action) {
	yield put(appActions.showLoading())
	const payload = action.payload
	const userInfo = yield select(currentUserInfo)

	let body = payload.data
	body['phone_number'] = userInfo.attributes.email

	const user = yield Auth.currentAuthenticatedUser()
	const token = user.signInUserSession.idToken.jwtToken
	const options = {
		headers: {
			Authorization: token
		}
	}
	try {
		const response = yield call(registerDispensary, body, options)
		if (response.status === 201) {
			if (payload.flowless) {
				yield put(actions.createDispensaryResponse(response))
				yield put(appActions.hideLoading())
				yield put(actions.resetForm())
				//show modal to success and button press go to dispensary portal
				yield put(
					appActions.showError({
						show: true,
						data: ErrorConstants.DISPENSARY_REGISTRATION.SUCCESS
					})
				)
			} else {
				//next step -> register doctor
				yield put(actions.createDispensaryResponse(response))
			}
		}
		yield put(appActions.hideLoading())
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(
			appActions.showError({
				show: true,
				data: ErrorConstants.DISPENSARY_REGISTRATION.FAILED
			})
		)
	}
}

export function* resendOtpSaga(action) {
	yield put(appActions.showLoading())
	const email = action.payload
	let response = {}
	try {
		response = yield Auth.resendSignUp(email)
		if (response) {
			let data = {
				header: 'Success',
				body: 'Your OTP code resent to ' + response.CodeDeliveryDetails.Destination,
				action: 'DISMISS',
				button_text: 'OK'
			}
			yield put(appActions.hideLoading())
			yield put(appActions.showError({ show: true, data: data }))
		}
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(
			appActions.showError({
				show: true,
				data: ErrorConstants.AUTH.RESENT_OTP_FAILED
			})
		)
	}
}

export function* updateDispensaryInfoSaga(action) {
	yield put(appActions.showLoading())
	const body = action.payload
	const userInfo = yield select(currentUserInfo)
	body['phone_number'] = userInfo.attributes.email

	const user = yield Auth.currentAuthenticatedUser()
	const token = user.signInUserSession.idToken.jwtToken
	const options = {
		headers: {
			Authorization: token
		}
	}

	try {
		const response = yield call(registerDispensary, body, options)
		if (response.status === 201) {
			yield put(appActions.hideLoading())
			yield put(
				appActions.showError({
					show: true,
					data: ErrorConstants.PROFILE.DISPENSARY_UPDATE_SUCCESS
				})
			)
		}
	} catch (error) {
		yield put(appActions.hideLoading())
		yield put(
			appActions.showError({
				show: true,
				data: ErrorConstants.PROFILE.DISPENSARY_UPDATE_FAILED
			})
		)
	}
}
