import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import * as dispensaryActions from './redux/actions'
import FormControlsLayout from 'containers/forms/components/FormControlsLayout'
import Controls from 'containers/forms/components/controls'
import Validators from 'utils/Validators'
import FormControlEnd from 'containers/forms/components/FormControlEnd'
// spacing = 1 (8px)
// rem = 1  (16px)
const styles = theme => ({
	root: {
		flexGrow: 1
	},

	formEndBox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: `${theme.spacing(5)}px ${theme.spacing(0)}px`,
		padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
		//borderTop: '1px dotted red',

		[theme.breakpoints.down('sm')]: {
			//padding: '1rem 0rem',
			padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`,
			//marginBottom: `${theme.spacing(5)}px`,
			margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`
		}
	}
})

class DispensaryOwnerForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			errors: {},
			firstName: '',
			lastName: '',
			email: '',
			emailConfirm: '',
			phoneNo:'',
			password: ''
		}
	}

	componentDidMount() {}

	validator = () => {
		const { errors } = this.state

		const { firstName, lastName, email, password, emailConfirm, phoneNo} = this.state

		

		errors.firstName = Validators.required(firstName) || Validators.minLength(firstName, 3)
		errors.lastName = Validators.required(lastName) || Validators.minLength(lastName, 3)
		errors.email = Validators.validateEmail(email)
		errors.emailConfirm = Validators.compareToEmail(emailConfirm, email)
		errors.password = Validators.minLength(password, 6)
		errors.phoneNo = Validators.required(phoneNo) || Validators.maxLength(phoneNo, 10) || Validators.minLength(phoneNo,9)
		this.setState(() => ({
			errors: errors
		}))

		return Object.values(errors).every(err => err === '' || err === undefined)
	}

	handleInputChange = e => {
		const { name, value } = e.target
		this.setState({ [name]: value }, () => console.log(this.state))
	}

	handleNext = e => {
		e.preventDefault()
		const { dispensaryActions } = this.props
		const { firstName, lastName, email, password, phoneNo } = this.state

		if (this.validator()) {
			let payload = {
				from: 'CREATE_DISPENSARY_OWNER',
				payload: {
					username: email,
					password: password,
					attributes: {
						given_name: firstName,
						family_name: lastName,
						email: email,
						'custom:mobile_number': phoneNo,
						'custom:role': 'owner'
					}
				}
			}
			dispensaryActions.awsCreateDispensaryOwnerAccountRequest(payload)
		}
	}

	render() {
		const { conditionTerms, handleTermsAndConditions } = this.props

		const { firstName, lastName, email, emailConfirm, password, phoneNo } = this.state

		const { errors } = this.state

		return (
			<FormControlsLayout onSubmit={this.handleNext}>
				<Grid container>
					<Grid container spacing={0} justifyContent='space-between'>
						<Grid item xs={12} sm={5} md={5} lg={5}>
							<Controls.InputText
								label='First Name'
								type='text'
								name='firstName'
								limit={20}
								value={firstName}
								onChange={this.handleInputChange}
								error={errors.firstName}
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={5} lg={5}>
							<Controls.InputText
								label='Last Name'
								type='text'
								name='lastName'
								value={lastName}
								onChange={this.handleInputChange}
								error={errors.lastName}
							/>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Controls.InputText
							label='Email Address'
							type='email'
							name='email'
							value={email}
							onChange={this.handleInputChange}
							error={errors.email}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controls.InputText
							label='Confirm Email Address'
							type='email'
							name='emailConfirm'
							value={emailConfirm}
							onChange={this.handleInputChange}
							error={errors.emailConfirm}
						/>
					</Grid>
					    <Grid item xs={12}>
							<Controls.InputText
								label='Phone Number'
								type='integer'
								name='phoneNo'
								limit={10}
								value={phoneNo}
								onChange={this.handleInputChange}
								error={errors.phoneNo}
							/>
						</Grid>


					<Grid item xs={12}>
						<Controls.InputPasswordText
							label='Password'
							type='password'
							name='password'
							value={password}
							onChange={this.handleInputChange}
							error={errors.password}
						/>
					</Grid>
					{/*  FORM_END_SECTION */}
					<Grid item xs={12}>
						{/* <Box className={classes.formEndBox}>
                            <Controls.CheckBox
                                label="By creating an account you agree to our Terms of Service and Privacy Policy."
                                name="conditionTerms"
                                value={conditionTerms}
                                onChange={handleTermsAndConditions}
                            />
                            <Widgets.ButtonBox
                                type="button"
                                disabled={!conditionTerms}
                                label="Next"
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.handleNext}
                            />
                        </Box> */}
						<FormControlEnd
							btnType='button'
							label='Next'
							isCheckbox={true}
							disabled={!conditionTerms}
							onChange={handleTermsAndConditions}
							onPressed={this.handleNext}
						/>
					</Grid>
				</Grid>
			</FormControlsLayout>
		)
	}
}

function mapStateToProps(state) {
	return {
		dispensaryReducer: state.dispensaryReducer
	}
}

function mapDispatchToProps(dispatch) {
	return {
		dispensaryActions: bindActionCreators(dispensaryActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DispensaryOwnerForm))
