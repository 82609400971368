import Axios from 'axios'
import { ApiConstants } from 'constants/ApiConstants'

export function getDoctorMetaData() {
	return Axios.get(ApiConstants.BASE_URL + 'app-configs')
}

export function submitDispensaryOwnerDoctor(body, options) {
	return Axios.post(ApiConstants.BASE_URL + 'doctor', body, options)
}

export function updateDoctorInfo(body, options) {
	return Axios.post(ApiConstants.BASE_URL + 'doctor', body, options)
}

export function assignDoctorToDispensary(body, options) {
	return Axios.post(ApiConstants.BASE_URL + 'dispensary/add_doctor', body, options)
}

export function sendEmailInvitation(body, options) {
	return Axios.post(ApiConstants.BASE_URL + 'doctor/send-invitation', body, options)
}
