import * as types from './types';

export function reset() {
    return {
        type: types.APPOINTMENTS_REDUCER_RESET
    };
}

export function requestAppointments(payload) {
    return {
        type: types.REQUEST_APPOINTMENTS,
        payload
    };
}

export function responseAppointments(payload) {
    return {
        type: types.RESPONSE_APPOINTMENTS,
        payload
    };
}

export function requestSearchPatient(payload) {
    return {
        type: types.REQUEST_SEARCH_PATIENT,
        payload
    };
}

export function responseSearchPatient(payload) {
    return {
        type: types.RESPONSE_SEARCH_PATIENT,
        payload
    };
}

export function changeSelectedPatient(payload) {
    return {
        type: types.CHANGE_SELECTED_PATIENT,
        payload
    };
}

export function requestAddNewPatient(payload) {
    return {
        type: types.REQUEST_ADD_NEW_PATIENT,
        payload
    };
}

export function responseAddNewPatient(payload) {
    return {
        type: types.RESPONSE_ADD_NEW_PATIENT,
        payload
    };
}

export function requestBookAppointment(payload) {
    return {
        type: types.REQUEST_BOOK_APPOINTMENT,
        payload
    };
}

export function responseBookAppointment(payload) {
    return {
        type: types.RESPONSE_BOOK_APPOINTMENT,
        payload
    };
}

export function requestAppointmentStatusUpdate(payload) {
    return {
        type: types.REQUEST_APPOINTMENT_STATUS_UPDATE,
        payload
    };
}

export function responseAppointmentStatusUpdate(payload) {
    return {
        type: types.RESPONSE_APPOINTMENT_STATUS_UPDATE,
        payload
    };
}

export function requestCancelAppointment(payload) {
    return {
        type: types.REQUEST_CANCEL_APPOINTMENT,
        payload
    };
}

export function responseCancelAppointment(payload) {
    return {
        type: types.RESPONSE_CANCEL_APPOINTMENT,
        payload
    };
}

export function updateModifySlot(payload) {
    return {
        type: types.UPDATE_MODIFY_SLOT,
        payload
    };
}

export function updateBookedSlot(payload) {
    return {
        type : types.UPDATE_BOOKED_SLOT,
        payload
    }
}

export function requestDoctorsByDispensary(payload) {
    return {
        type: types.REQUEST_DOCTORS_BY_DISPENSARY,
        payload
    };
}

export function responseDoctorsByDispensary(payload) {
    return {
        type: types.RESPONSE_DOCTORS_BY_DISPENSARY,
        payload
    };
}

export function updateDoctorList(payload) {
    return {
        type: types.UPDATE_DOCTOR_LIST,
        payload
    };
}

export function navigateToKiosk() {
    return {
        type: types.NAVIGATE_TO_KIOSK
    };
}

export function navigateToKioskMobile(payload) {
    return {
        type: types.NAVIGATE_TO_KIOSK_MOBILE,
        payload
    };
}

export function setFirstName(payload) {
    return {
        type: types.KIOSK_FIRST_NAME,
        payload
    };
}