export const UPDATE_CURRENT_FORM_STEP = 'UPDATE_CURRENT_FORM_STEP'
export const SET_LOGIN_FORM_FIELD_VALUE = 'SET_LOGIN_FORM_FIELD_VALUE'
export const LOGIN_DISPENSARY_PORTAL = 'LOGIN_DISPENSARY_PORTAL'
export const RESET_LOGIN_FORM_VALUES = 'RESET_LOGIN_FORM_VALUES'
export const LOAD_USER_AUTH_VALUE = 'LOAD_USER_AUTH_VALUE'
export const CHECK_LOGIN_STATUS = 'CHECK_LOGIN_STATUS'
export const SET_LOGIN_STATE = 'SET_LOGIN_STATE'

export const GET_CURRENT_USER_INFO = 'GET_CURRENT_USER_INFO'
export const UPDATE_CURRENT_USER_INFO = 'UPDATE_CURRENT_USER_INFO'
export const SET_TEMP_USER_INFO = 'SET_TEMP_USER_INFO'
export const DISPENSARY_OWNER_SIGN_IN_REQUEST = 'DISPENSARY_OWNER_SIGN_IN_REQUEST'
export const DISPENSARY_OWNER_SIGN_IN_RESPONSE = 'DISPENSARY_OWNER_SIGN_IN_RESPONSE'
export const SET_LOGGED_IN = 'SET_LOGGED_IN'
export const SIGNOUT = 'SIGNOUT'
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'

export const SET_TEMP_DISPENSARY = 'SET_TEMP_DISPENSARY'
export const CLEAR_TEMP_DISPENSARY = 'CLEAR_TEMP_DISPENSARY'

export const SET_TEMP_DOCTOR_PAYLOAD = 'SET_TEMP_DOCTOR_PAYLOAD'

export const CLEAR_TEMP_DATA = 'CLEAR_TEMP_DATA'
