export const SET_STAFF_FORM_FIELD_VALUE = 'SET_STAFF_FORM_FIELD_VALUE'
export const SUBMIT_DISPENSARY_VALUES = 'SUBMIT_DISPENSARY_VALUES'
export const RESET_DISPENSARY_STAFF_FORM_VALUES = 'RESET_DISPENSARY_STAFF_FORM_VALUES'
export const RESPONSE_CREATE_STAFF = 'RESPONSE_CREATE_STAFF'
export const RESPONSE_GET_STAFF = 'RESPONSE_GET_STAFF'
export const UPDATE_USER_ROLE_STATUS = 'UPDATE_USER_ROLE_STATUS'
export const SET_STAFF_OTP = 'SET_STAFF_OTP'
export const RESPONSE_API_CREATE_STAFF = 'RESPONSE_API_CREATE_STAFF'

export const CREATE_STAFF_MEMBER_REQUEST = 'CREATE_STAFF_MEMBER_REQUEST'
export const CREATE_STAFF_MEMBER_RESPONSE = 'CREATE_STAFF_MEMBER_RESPONSE'

export const SUBMIT_DISPENSARY_STAFF_API_REQUEST = 'SUBMIT_DISPENSARY_STAFF_API_REQUEST'
export const SUBMIT_DISPENSARY_STAFF_API_RESPONSE = 'SUBMIT_DISPENSARY_STAFF_API_RESPONSE'

export const UPDATE_CURRENT_FORM_STEP = 'UPDATE_CURRENT_FORM_STEP'

export const SET_STAFF_DETAILS_TO_FORM = 'SET_STAFF_DETAILS_TO_FORM'
export const UPDATE_STAFF_PASSWORD = 'UPDATE_STAFF_PASSWORD'
export const UPDATE_DISPENSARY_STAFF = 'UPDATE_DISPENSARY_STAFF'

export const STAFF_FORGOT_PASSWORD_RESPONSE = 'STAFF_FORGOT_PASSWORD_RESPONSE'
export const SUBMIT_STAFF_PASSWORD_REQUEST = 'SUBMIT_STAFF_PASSWORD_REQUEST'
export const SUBMIT_STAFF_PASSWORD_RESPONSE = 'SUBMIT_STAFF_PASSWORD_RESPONSE'
export const CONFIRM_STAFF_USER_REQUEST = 'CONFIRM_STAFF_USER_REQUEST'
export const CONFIRM_STAFF_USER_RESPONSE = 'CONFIRM_STAFF_USER_RESPONSE'
