import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Box, Container } from '@material-ui/core'

import * as portalActions from '../../portalweb/redux/actions'
import * as dispensaryActions from './redux/actions'
import * as dispensaryStaffActions from './redux/dispensaryStaffActions'

import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { FORM_DISPENSARY_STAFF } from 'constants/FormConstants'
import FormWizardLayout from '../components/FormWizardLayout'
import SuccessWizardLayout from '../components/shared/SuccessWizardLayout'
import DispensaryStaffForm from './DispensaryStaffForm'
import { AWSStatusCode } from 'config/AWSStatusCode'
import FormOTPWizardLayout from '../components/shared/FormOTPWizardLayout'

const styles = () => ({
	root: {
		flexGrow: 1
	}
})

class DispensaryStaffLayout extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dispensary: {},
			edit: this.props.location.edit
		}
	}

	componentDidMount() {
		if (this.props.location.staff) {
			const { dispensaryStaffActions } = this.props
			dispensaryStaffActions.updateFormStep(2)
			let data = {
				isAdmin: this.props.location.staff.role === 1 ? true : false,
				firstName: this.props.location.staff.first_name,
				lastName: this.props.location.staff.last_name,
				username: this.props.location.staff.username
			}
			dispensaryStaffActions.setCurrentStaffMemberDetails(data)
		} else {
			const { dispensaryStaffActions } = this.props
			dispensaryStaffActions.updateFormStep(2)
		}
	}

	componentDidUpdate(prevProps) {
		const {
			currentUserInfo,
			dispensaryStaffState,
			dispensaryStaffActions,
			responseCreateStaff,
			assignStaffToDispensaryResponse,
			staffForgotPasswordResponse,
			confirmStaffUserResponse
		} = this.props
		const { formInputs } = dispensaryStaffState
		const { dispensary } = this.state
		const prevDispensaryResponse = prevProps.portalState.dispensaryDetails
		const currentDispensaryResponse = this.props.portalState.dispensaryDetails

		if (prevDispensaryResponse !== currentDispensaryResponse) {
			if (currentDispensaryResponse) {
				this.setState({ dispensary: currentDispensaryResponse.data }, () =>
					console.log('disp')
				)
			}
		}

		if (prevProps.responseCreateStaff.uuid !== responseCreateStaff.uuid) {
			//NAVIGATE TO CONFIRM OTP SCREEN
			if (responseCreateStaff.status === AWSStatusCode.SUCCESS) {
				this.nextStep()
			}
		}

		if (prevProps.confirmStaffUserResponse.uuid !== confirmStaffUserResponse.uuid) {
			if (confirmStaffUserResponse.status === AWSStatusCode.SUCCESS) {
				let payload = {
					first_name: formInputs.firstName,
					last_name: formInputs.lastName,
					username: formInputs.username,
					role: formInputs.isAdmin ? 1 : 0,
					dispensary_id: currentUserInfo.attributes.email
				}
				dispensaryStaffActions.submitDispensaryStaffForm(payload)
			}
		}

		if (prevProps.assignStaffToDispensaryResponse.uuid !== assignStaffToDispensaryResponse.uuid) {
			if (assignStaffToDispensaryResponse.status === 201) {
				this.nextStep()
			}
		}
	}

	handleRoleCondition = e => {
		const { dispensaryStaffActions } = this.props
		const checked = e.target.checked
		if (checked) {
			dispensaryStaffActions.updateUserRoleCondition(e.target.name, true)
		} else {
			dispensaryStaffActions.updateUserRoleCondition(e.target.name, false)
		}
	}

	handleInputChange = e => {
		const { dispensaryStaffActions } = this.props
		const { name, value } = e.target
		dispensaryStaffActions.onChangeField(name, value)
	}

	registerStaffAws = edit => {
		const { currentUserInfo, dispensaryStaffState, dispensaryStaffActions } = this.props
		const { formInputs } = dispensaryStaffState
		if (edit) {
			let payload = {
				first_name: formInputs.firstName,
				last_name: formInputs.lastName,
				username: formInputs.username,
				role: formInputs.isAdmin ? 1 : 0,
				dispensary_id: currentUserInfo.attributes.email
			}
			dispensaryStaffActions.updateDispensaryStaff(payload)
		} else {
			let awsPayload = {
				username: formInputs.username,
				password: formInputs.password,
				attributes: {
					email: formInputs.username,
					given_name: formInputs.firstName,
					family_name: formInputs.lastName,
					'custom:role': 'staff'
				}
			}
			dispensaryStaffActions.createStaffMemberAws(awsPayload)
		}
	}

	updatePassword = () => {
		const { dispensaryStaffActions, dispensaryStaffState } = this.props
		const { formInputs } = dispensaryStaffState
		const { dispensary } = this.state

		dispensaryStaffActions.resetStaffPassword(formInputs.username)
	}

	nextStep = () => {
		const { dispensaryStaffState, dispensaryStaffActions } = this.props
		const { step } = dispensaryStaffState
		const nextPageCount = step + 1
		dispensaryStaffActions.updateFormStep(nextPageCount)
	}

	prevStep = () => {
		const { history, dispensaryState, dispensaryActions } = this.props
		const { step } = dispensaryState
		const prevPageCount = step - 1

		if (step === 2) {
			history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
			dispensaryStaffActions.resetForm()
		} else {
			dispensaryActions.updateFormStep(prevPageCount !== 0 ? prevPageCount : 1)
		}
	}

	exitForm = () => {
		const { history, dispensaryStaffActions } = this.props
		history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
		dispensaryStaffActions.resetForm()
	}

	submitStaffPasswordReset = data => {
		const { dispensaryStaffActions } = this.props
		dispensaryStaffActions.submitStaffPasswordResetRequest(data)
	}

	render() {
		const { dispensaryStaffState } = this.props
		const { step, title, formInputs } = dispensaryStaffState
		const { dispensary, edit } = this.state

		switch (step) {
			case 2:
				return (
					<FormWizardLayout
						showHeading={true}
						mainHeading={title}
						subHeading={null}
						stepper={null}
						prevArrow={true}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						exitForm={this.exitForm}>
						<Container maxWidth='lg'>
							<DispensaryStaffForm
								edit={edit}
								suffix={`@${dispensary.br_number}`}
								data={formInputs}
								onSubmit={this.registerStaffAws}
								nextStep={this.nextStep}
								handleInputChange={this.handleInputChange}
								handleRoleCondition={this.handleRoleCondition}
								updatePassword={this.updatePassword}
								submitPasswordReset={this.submitStaffPasswordReset}
							/>
						</Container>
					</FormWizardLayout>
				)
			case 3:
				return <FormOTPWizardLayout formType={FORM_DISPENSARY_STAFF} email={formInputs.username} />
			// eslint-disable-next-line no-duplicate-case
			case 4:
				return (
					<SuccessWizardLayout
						history={this.props.history}
						formType={FORM_DISPENSARY_STAFF}
						isLogo={false}
						username={null}
					/>
				)

			default:
				return <Box>Error</Box>
		}
	}
}

function mapStateToProps(state) {
	return {
		currentUserInfo: state.authReducer.currentUserInfo,
		portalState: state.portalReducer,
		dispensaryState: state.dispensaryReducer,
		dispensaryStaffState: state.staffReducer,
		responseCreateStaff: state.staffReducer.responseCreateStaff,
		assignStaffToDispensaryResponse: state.staffReducer.assignStaffToDispensaryResponse,
		staffForgotPasswordResponse: state.staffReducer.staffForgotPasswordResponse,
		confirmStaffUserResponse: state.staffReducer.confirmStaffUserResponse
	}
}

function mapDispatchToProps(dispatch) {
	return {
		portalActions: bindActionCreators(portalActions, dispatch),
		dispensaryActions: bindActionCreators(dispensaryActions, dispatch),
		dispensaryStaffActions: bindActionCreators(dispensaryStaffActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DispensaryStaffLayout))
