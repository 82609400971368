import * as types from './types'

const defaultState = {
	dispensaryDetails: null,
	doctorsList: [],
	staffList: []
}

const portalReducer = (state = defaultState, action) => {
	switch (action.type) {
		case types.SET_DISPENSARY_DETAILS:
			return {
				...state,
				dispensaryDetails: action.payload
			}
		case types.SET_DOCTOR_LIST:
			return {
				...state,
				doctorsList: action.payload
			}
		case types.SET_STAFF:
			return {
				...state,
				staffList: action.payload
			}
		default:
			return state
	}
}

export default portalReducer
