import React, { useState, useEffect, Fragment } from 'react'
import { Box, Container } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'

import { Done } from '@material-ui/icons'

var classes = null

const useStyles = makeStyles(theme => ({
	stepRowBox: {
		flexGrow: 1,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		pointerEvents: 'all'
	},

	stepBoxActive: {
		textAlign: 'center',
		maxWidth: '28px',
		maxHeight: '28px',
		position: 'relative',
		backgroundColor: '#39CFBA',
		padding: '3px 8px',
		borderRadius: '50%',
		border: '2px solid #39CFBA',
		pointerEvents: 'all',

		'&:first-child': {
			marginRight: '32px',
			'&:after': {
				content: '',
				position: 'absolute',
				bottom: '0',
				left: '-6px',
				right: '0',
				borderTop: '3px solid #39CFBA',
				zIndex: '1'
			}
		}
	},
	stepBoxInActive: {
		textAlign: 'center',
		maxWidth: '28px',
		maxHeight: '28px',
		position: 'relative',
		backgroundColor: '#FFF',
		padding: '3px 8px',
		borderRadius: '50%',
		border: '2px solid #39CFBA',
		pointerEvents: 'all',

		'&:first-child': {
			marginRight: '32px',
			'&:after': {
				content: '',
				position: 'absolute',
				bottom: '0',
				left: '-6px',
				right: '0',
				borderTop: '3px solid #39CFBA',
				zIndex: '1'
			}
		}
	},

	stepBoxLabelActive: {
		textAlign: 'center',
		cursor: 'pointer',
		color: '#FFF ',
		fontFamily: 'Roboto Bold,sans-serif',
		marginLeft: '0px'
	},
	stepBoxLabelInActive: {
		textAlign: 'center',
		cursor: 'pointer',
		color: '#39CFBA',
		fontFamily: 'Roboto Bold ,sans-serif',
		marginLeft: '0px'
	},

	doneIcon: {
		marginTop: '-2px',
		marginLeft: '-5px',
		fontSize: '20px',
		color: '#FFF'
	}
}))

const getSteps = data => {
	const [stepOne, stepTwo] = data
	return [stepOne, stepTwo]
}

const TrainStopers = props => {
	const { stepper } = props
	const classes = useStyles()
	const theme = useTheme()

	const steps = getSteps(stepper)

	return (
		<Box component='div' className={classes.stepRowBox}>
			{steps.map(step => {
				const { activeStep, completedStep } = step
				if (activeStep || completedStep) {
					return (
						<Box component='div' className={`${classes.stepBoxActive} step_box`} key={step.id}>
							{!completedStep ? (
								<Box component='span' className={classes.stepBoxLabelActive}>
									{step.label}
								</Box>
							) : (
								<Box component='span' className={classes.stepBoxLabel}>
									<Done className={classes.doneIcon} />
								</Box>
							)}
						</Box>
					)
				} else {
					return (
						<Box component='div' className={`${classes.stepBoxInActive} step_box`} key={step.id}>
							{!completedStep ? (
								<Box component='span' className={classes.stepBoxLabelInActive}>
									{step.label}
								</Box>
							) : (
								<Box component='span' className={classes.stepBoxLabel}>
									<Done className={classes.doneIcon} />
								</Box>
							)}
						</Box>
					)
				}
			})}
		</Box>
	)
}

export default TrainStopers
